// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".signin_content__1FBEd {\n  width: 100%;\n  min-height: calc(100% - 30px);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 20px;\n}\n.signin_content__1FBEd a {\n  color: #F01A33;\n}\n.signin_content__1FBEd a:hover {\n  text-decoration: underline;\n}", "",{"version":3,"sources":["webpack://./src/pages/auth/signin/index.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,6BAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AACJ;AAAI;EACI,cAAA;AAER;AADQ;EACI,0BAAA;AAGZ","sourcesContent":[".content {\n    width: 100%;\n    min-height: calc(100% - 30px);\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 20px;\n    a {\n        color: #F01A33;\n        &:hover {\n            text-decoration: underline;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "signin_content__1FBEd"
};
export default ___CSS_LOADER_EXPORT___;
