import { Link } from "react-router-dom";

import { SignupForm } from "forms/signup";

import styles from "./index.module.scss";
import { useAppSelector } from "state/store";

export function SignupPage() {
    const { isSuccess } = useAppSelector((state) => state.core);
    return (
        <div className={styles.content}>
            {isSuccess ? (
                <>
                    <h2>Аккаунт успешно создан</h2>
                    <p>На указанную вами электронную почту было отправлено сообщение с ссылкой для верификации аккаунта.</p>
                    <p>Если сообщение не пришло, проверьте папку СПАМ.</p>
                </>
            ) : (
                <>
                    <h2>Регистрация</h2>
                    <p>
                        Уже есть аккаунт? <Link to="/signin" children="Авторизация" />
                    </p>
                    <SignupForm />
                </>
            )}
        </div>
    );
}
