// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button_btn__Op9kT {\n  font-size: 16px;\n  color: #F01A33;\n  cursor: pointer;\n  background-color: transparent;\n  border: #F01A33 2px solid;\n  padding: 6px 12px 6px 12px;\n  border-radius: 6px;\n  transition: 0.25s;\n}\n.button_btn__Op9kT:hover {\n  color: white;\n  background-color: #F01A33;\n}", "",{"version":3,"sources":["webpack://./src/ui/button/index.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,cAAA;EACA,eAAA;EACA,6BAAA;EACA,yBAAA;EACA,0BAAA;EACA,kBAAA;EACA,iBAAA;AACJ;AACI;EACI,YAAA;EACA,yBAAA;AACR","sourcesContent":[".btn {\n    font-size: 16px;\n    color: #F01A33;\n    cursor: pointer;\n    background-color: transparent;\n    border: #F01A33 2px solid;\n    padding: 6px 12px 6px 12px;\n    border-radius: 6px;\n    transition: 0.25s;\n\n    &:hover {\n        color: #ffff;\n        background-color: #F01A33;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "button_btn__Op9kT"
};
export default ___CSS_LOADER_EXPORT___;
