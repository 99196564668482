import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ApiService } from "axios.api";

export const getPolitics = createAsyncThunk<Paragraph[]>("politics/getPolitics", async function (_, thunkAPI) {
    try {
        return await ApiService.get("/politics/paragraphs");
    } catch (error) {
        const message = ((error as AxiosError).response?.data as Error).message;
        return thunkAPI.rejectWithValue(message);
    }
});
