import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ApiService } from "axios.api";

export const getAllUsers = createAsyncThunk<[User[], number], PaginationForm>(
    "users/getAll",
    async function (form, thunkAPI) {
        try {
            return await ApiService.get("/user", { params: form });
        } catch (error) {
            const message = ((error as AxiosError).response?.data as Error).message;
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const searchUsers = createAsyncThunk<[User[], number], { pattern: string; form: PaginationForm }>(
    "users/search",
    async function (data, thunkAPI) {
        try {
            return await ApiService.get(`/user/search/${data.pattern}`, { params: data.form });
        } catch (error) {
            const message = ((error as AxiosError).response?.data as Error).message;
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const setPayment = createAsyncThunk<string, string>("users/setPayment", async function (id, thunkAPI) {
    try {
        await ApiService.get(`/user/payment/${id}`);
        return id;
    } catch (error) {
        const message = ((error as AxiosError).response?.data as Error).message;
        return thunkAPI.rejectWithValue(message);
    }
});

export const deleteUser = createAsyncThunk<string, string>("users/deleteUser", async function (id, thunkAPI) {
    try {
        await ApiService.delete(`/user/${id}`);
        return id;
    } catch (error) {
        const message = ((error as AxiosError).response?.data as Error).message;
        return thunkAPI.rejectWithValue(message);
    }
});
