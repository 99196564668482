// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".layout_container__KUiiM {\n  height: calc(100vh - 90px);\n  margin-top: 90px;\n  overflow-y: scroll;\n  overflow-x: hidden;\n}\n@media screen and (max-width: 600px) {\n  .layout_container__KUiiM {\n    height: calc(100vh - 130px);\n    margin-top: 130px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/layout/index.module.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;AACJ;AAAI;EALJ;IAMQ,2BAAA;IACJ,iBAAA;EAGF;AACF","sourcesContent":[".container {\n    height: calc(100vh - 90px);\n    margin-top: 90px;\n    overflow-y: scroll;\n    overflow-x: hidden;\n    @media screen  and (max-width: 600px){\n        height: calc(100vh - 130px);\n    margin-top: 130px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "layout_container__KUiiM"
};
export default ___CSS_LOADER_EXPORT___;
