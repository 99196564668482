import { Footer } from "../footer";
import { Header } from "../header";
import { Menu } from "../menu";
import { Outlet } from "react-router-dom";
import { Fragment, useState } from "react";
import styles from "./index.module.scss";

export function Layout() {
    const [menuIsActive, setMenuActivity] = useState<boolean>(false);
    return (
        <Fragment>
            <Header menuIsActive={menuIsActive} setMenuActivity={setMenuActivity} />
            <div className={styles.container}>
                <Outlet />
                <Footer />
            </div>
            <Menu menuIsActive={menuIsActive} setMenuActivity={setMenuActivity} />
        </Fragment>
    );
}
