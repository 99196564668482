// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".category_form__5VOLT {\n  width: 360px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n}\n@media screen and (max-width: 400px) {\n  .category_form__5VOLT {\n    width: 300px;\n  }\n}\n.category_form__5VOLT h2 {\n  margin-bottom: 20px;\n  color: black;\n}\n.category_form__5VOLT div {\n  width: 100%;\n}\n.category_form__5VOLT button {\n  margin-top: 20px;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/forms/create/category/index.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;AACJ;AAAI;EANJ;IAOQ,YAAA;EAGN;AACF;AAFI;EACI,mBAAA;EACA,YAAA;AAIR;AAFI;EACI,WAAA;AAIR;AAFI;EACI,gBAAA;EACA,WAAA;AAIR","sourcesContent":[".form {\n    width: 360px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 10px;\n    @media screen and (max-width: 400px){\n        width: 300px;\n    }\n    h2 {\n        margin-bottom: 20px;\n        color: black;\n    }\n    div {\n        width: 100%;\n    }\n    button {\n        margin-top: 20px;\n        width: 100%;\n\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "category_form__5VOLT"
};
export default ___CSS_LOADER_EXPORT___;
