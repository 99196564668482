import React from "react";

import styles from "./index.module.scss";

export function RadioSet({ options, initialValue, onChange }: RadioSet) {
    function handleRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        onChange(value);
    }

    return (
        <div className={styles.set}>
            {options.map((option) => (
                <label key={option.value}>
                    <input
                        type="radio"
                        value={option.value}
                        checked={initialValue === option.value}
                        onChange={handleRadioChange}
                    />
                    {option.label}
                </label>
            ))}
        </div>
    );
}
