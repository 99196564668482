import { useForm } from "react-hook-form";

import { Input } from "ui/input";
import { Button } from "ui/button";
import { useActions } from "state/store";

import styles from "./index.module.scss";
import { Textarea } from "ui/textarea";

export function EditCategory({ category, closeModal }: { category: Category; closeModal: () => void }) {
    const { updateCategory } = useActions();

    const {
        register,
        formState: { errors, isValid },
        handleSubmit
    } = useForm({ mode: "onChange" });

    function onSubmit(data: any) {
        const form: Record<string, any> = {};
        for (const key in data) {
            if (data[key]) {
                form[key] = data[key];
            }
        }
        updateCategory({ form: form as CategoriesAPI.UpdateCategoryForm, id: category.id });
        closeModal();
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <h2>Изменение категории</h2>
            <Input
                name="title"
                label="Название категории"
                type="text"
                errors={errors}
                register={register("title", {
                    minLength: {
                        value: 3,
                        message: "Название категории слишком короткое"
                    },
                    maxLength: {
                        value: 50,
                        message: "Название категории слишком длинное"
                    }
                })}
            />
            <Textarea
                name="description"
                label="Описание категории"
                errors={errors}
                register={register("description", {
                    maxLength: {
                        value: 999,
                        message: "Описание категории слишком длинное"
                    }
                })}
            />
            <Button type="submit" disabled={!isValid}>
                Изменить
            </Button>
        </form>
    );
}
