import { createSlice } from "@reduxjs/toolkit";

import * as videosActions from "./actions";

const initialState: VideosState = {
    video: null,
    videos: [],
    count: 0,
    isSuccess: false,
    isLoading: false,
    isError: false
};

export const videosSlice = createSlice({
    name: "videos",
    initialState,
    reducers: {
        clearState(state) {
            state.video = null;
            state.videos = [];
            state.count = 0;
            state.isSuccess = false;
            state.isLoading = false;
            state.isError = false;
            return state;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(videosActions.getAllVideos.pending, (state: VideosState) => {
                state.isLoading = true;
            })
            .addCase(videosActions.getAllVideos.fulfilled, (state: VideosState, action) => {
                state.videos = action.payload[0];

                state.count = action.payload[1];
                state.isSuccess = true;
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(videosActions.getAllVideos.rejected, (state: VideosState) => {
                state.videos = [];
                state.count = 0;
                state.isSuccess = false;
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(videosActions.getVideo.pending, (state: VideosState) => {
                state.isLoading = true;
            })
            .addCase(videosActions.getVideo.fulfilled, (state: VideosState, action) => {
                state.video = action.payload;
                state.isSuccess = true;
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(videosActions.getVideo.rejected, (state: VideosState) => {
                state.video = null;
                state.isSuccess = false;
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(videosActions.createVideo.pending, (state: VideosState) => {
                state.isLoading = true;
            })
            .addCase(videosActions.createVideo.fulfilled, (state: VideosState, action) => {
                state.videos = state.videos.concat(action.payload);
                // eslint-disable-next-line no-console
                console.log(action.payload);
                ++state.count;
                state.isSuccess = true;
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(videosActions.createVideo.rejected, (state: VideosState) => {
                state.isSuccess = false;
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(videosActions.deleteVideo.pending, (state: VideosState) => {
                state.isLoading = true;
            })
            .addCase(videosActions.deleteVideo.fulfilled, (state: VideosState, action) => {
                state.videos = state.videos.filter((video) => video.id !== action.payload);
                --state.count;
                state.isSuccess = true;
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(videosActions.deleteVideo.rejected, (state: VideosState) => {
                state.isSuccess = false;
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(videosActions.updateVideo.pending, (state: VideosState) => {
                state.isLoading = true;
            })
            .addCase(videosActions.updateVideo.fulfilled, (state: VideosState, { payload }) => {
                state.videos = state.videos
                    .map((category) => (category.id === payload.id ? payload : category))
                    .sort((a: Video, b: Video) => a.title.localeCompare(b.title));
                state.isSuccess = true;
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(videosActions.updateVideo.rejected, (state: VideosState) => {
                state.isSuccess = false;
                state.isLoading = false;
                state.isError = true;
            });
    }
});

export const { clearState } = videosSlice.actions;

export const videosSelector = (state: { videos: VideosState }) => state.videos;
