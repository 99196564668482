// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".signup_form__5JGKd {\n  width: 360px;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n@media screen and (max-width: 400px) {\n  .signup_form__5JGKd {\n    width: 300px;\n  }\n}\n.signup_form__5JGKd .signup_agreement__2LVuT {\n  font-size: 14px;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n.signup_form__5JGKd .signup_agreementRed__02apS {\n  font-size: 14px;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  box-shadow: 0px 0px 15px red;\n}", "",{"version":3,"sources":["webpack://./src/forms/signup/index.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AAAI;EALJ;IAMQ,YAAA;EAGN;AACF;AAFI;EACI,eAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;AAIR;AAFI;EACI,eAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,4BAAA;AAIR","sourcesContent":[".form {\n    width: 360px;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    @media screen and (max-width: 400px){\n        width: 300px;\n    }\n    .agreement {\n        font-size: 14px;\n        display: flex;\n        align-items: center;\n        gap: 8px;\n    }\n    .agreementRed {\n        font-size: 14px;\n        display: flex;\n        align-items: center;\n        gap: 8px;\n        box-shadow: 0px 0px 15px red;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "signup_form__5JGKd",
	"agreement": "signup_agreement__2LVuT",
	"agreementRed": "signup_agreementRed__02apS"
};
export default ___CSS_LOADER_EXPORT___;
