import { createElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

import { useActions, useAppSelector } from "state/store";
import { Button } from "ui/button";
import { Modal } from "components/modal";
import { CreateCategory } from "forms/create/category";
import { EditCategory } from "forms/edit/category";
import { DeleteCategory } from "forms/delete/category";

import styles from "./index.module.scss";

const forms: Record<ModalForm, ({ category, closeModal }: { category: Category; closeModal: () => void }) => JSX.Element> =
    {
        create: CreateCategory,
        update: EditCategory,
        delete: DeleteCategory
    };

export function CategoriesPage() {
    const { user } = useAppSelector((state) => state.core);
    const { categories, count } = useAppSelector((state) => state.categories);
    const { getAllCategories } = useActions();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [modalType, setModalType] = useState<ModalForm>("create");
    const [currentCategory, setCurrentCategory] = useState<Category>(categories[0]);
    const [skip, setSkip] = useState(0);

    useEffect(() => {
        getAllCategories({ skip: `${skip}`, take: "9" });
    }, [skip]);

    function editHandler(category: Category) {
        setIsOpen(true);
        setModalType("update");
        setCurrentCategory(category);
    }

    function createHandler() {
        setIsOpen(true);
        setModalType("create");
    }

    function deleteHandler(category: Category) {
        setIsOpen(true);
        setModalType("delete");
        setCurrentCategory(category);
    }

    const handlePageClick = (event: { selected: number }) => {
        const offset = (event.selected * 9) % count;
        setSkip(offset);
    };

    return (
        <div className={styles.container}>
            <h2>Категории социальных инструментов</h2>
            {user?.isAdmin && <Button onClick={createHandler} children={"Создать категорию"} />}
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={Math.ceil(count / 9)}
                previousLabel="<"
                renderOnZeroPageCount={null}
                containerClassName={styles.pagination}
                previousLinkClassName={styles.prev}
                nextLinkClassName={styles.next}
                pageLinkClassName={styles.page}
                activeLinkClassName={styles.active}
            />
            <div className={styles.grid}>
                {categories.length ? (
                    categories.map((category: Category, index: number) => (
                        <div key={category.id} className={styles.category}>
                            <h3>{index + 1 > 9 || skip >= 9 ? skip + index + 1 : `0${index + 1}`}</h3>
                            <p>{category.title}</p>
                            <div>
                                <Link to={`/category/${category.id}`} children={<Button children={"Смотреть"} />} />
                                {user?.isAdmin && <Button onClick={() => editHandler(category)} children={"Изменить"} />}
                                {user?.isAdmin && <Button onClick={() => deleteHandler(category)} children={"Удалить"} />}
                            </div>
                        </div>
                    ))
                ) : (
                    <p>Не найдено ни одной категории</p>
                )}
            </div>
            {isOpen && (
                <Modal
                    closeModal={() => setIsOpen(false)}
                    children={createElement(forms[modalType], {
                        category: currentCategory,
                        closeModal: () => setIsOpen(false)
                    })}
                />
            )}
        </div>
    );
}
