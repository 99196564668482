import { createSlice } from "@reduxjs/toolkit";

import * as politicsActions from "./actions";

const initialState: PoliticsState = {
    politics: [],
    isSuccess: false,
    isLoading: false,
    isError: false,
    count: 0
};

export const politicsSlice = createSlice({
    name: "politics",
    initialState,
    reducers: {
        clearState(state) {
            state.politics = [];
            state.isSuccess = false;
            state.isLoading = false;
            state.isError = false;
            state.count = 0;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(politicsActions.getPolitics.pending, (state: PoliticsState) => {
                state.isLoading = true;
            })
            .addCase(politicsActions.getPolitics.fulfilled, (state: PoliticsState, action) => {
                state.politics = action.payload;
                state.isSuccess = true;
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(politicsActions.getPolitics.rejected, (state: PoliticsState) => {
                state.politics = [];
                state.isSuccess = false;
                state.isLoading = false;
                state.isError = true;
            });
    }
});

export const { clearState } = politicsSlice.actions;

export const politicsSelector = (state: { politics: PoliticsState }) => state.politics;
