import cn from "classnames";

import styles from "./oferta.module.scss";

export function Oferta() {
    return (
        <section className={cn(styles.oferta, styles.section)}>
            <div className={styles.container}>
                <div className={styles.WordSection1}>
                    <p
                        className={styles.MsoNormal}
                        style={{ marginBottom: "0in", textAlign: "center", lineHeight: "normal" }}>
                        <b>
                            <span>ПУБЛИЧНАЯ ОФЕРТА</span>
                        </b>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{ marginBottom: "0in", textAlign: "center", lineHeight: "normal" }}>
                        <b>
                            <span>о заключении договора возмездного оказания </span>
                        </b>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{ marginBottom: "0in", textAlign: "center", lineHeight: "normal" }}>
                        <b>
                            <span>информационных услуг</span>
                        </b>
                    </p>
                    <p className={styles.MsoNormal} style={{ marginBottom: "0in", lineHeight: "normal" }}>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </p>
                    <p className={styles.MsoNormal} style={{ marginBottom: "0in", lineHeight: "normal" }}>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            marginBottom: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            background: "white",
                            border: "none"
                        }}>
                        <span>
                            Настоящая публичная оферта (далее – «оферта») представляет собой официальное предложение
                            Индивидуального предпринимателя Утюж Ильи Николаевича (далее - «Исполнитель»), на заключение
                            договора оказания информационных услуг, адресованного лицам, принявшим (акцептовавшим) настоящую
                            оферту, далее именуемым «Заказчик», на перечисленных ниже условиях.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            marginBottom: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            background: "white",
                            border: "none"
                        }}>
                        <span>
                            Акцепт оферты равносилен заключению договора на условиях, изложенных в оферте. Заказчик обязан
                            полностью ознакомиться с настоящим документом до момента начала поиска Услуг и оформления Заказа
                            Услуг.{" "}
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            marginBottom: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            background: "white",
                            border: "none"
                        }}>
                        <span>
                            Оформляя Заказ Услуг, Заказчик выражает свое безоговорочное согласие со всеми условиями
                            настоящей Оферты и обязуется их соблюдать на следующих условиях:
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            marginBottom: "0in",
                            textAlign: "center",
                            lineHeight: "normal",
                            background: "white"
                        }}>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </p>
                    <ol style={{ margin: "0.25in" }} start={1}>
                        <li
                            className={styles.MsoNormal}
                            style={{
                                color: "black",
                                marginBottom: "0.1in",
                                textAlign: "center",
                                lineHeight: "normal",
                                border: "none"
                            }}>
                            <b>
                                <span lang="RU" style={{}}>
                                    Определения и термины
                                </span>
                            </b>
                        </li>
                    </ol>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>1.1.</span>
                        <span>Нижеприведенные термины используются в настоящем документе в следующем значении: </span>
                    </p>
                    <p
                        className={styles.MsoListParagraphCxSpFirst}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            background: "white"
                        }}>
                        <span>
                            <strong>«Заказчик»</strong>- физическое лицо, посетитель Сайта, который знакомится с видами
                            Услуг, представленных Исполнителем, пользуется справочными и информационными ресурсами Сайта,
                            собирается сделать Заказ Услуг, или уже осуществляет Заказ в режиме реального времени, или
                            который уже использует Услуги, приобретенные им ранее у Исполнителя.
                        </span>
                    </p>
                    <p
                        className={styles.MsoListParagraphCxSpLast}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            <strong>«Исполнитель»</strong> - ИП Утюж И.Н., ОГРНИП 322774600747400, ИНН 772864420416, который
                            дистанционно оказывает Услуги Заказчику.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            marginBottom: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            <strong>«Сайт»</strong> - Интернет-ресурс Исполнителя, расположенный по адресу{" "}
                        </span>
                        <span lang="RU">
                            <a href="https://lindent-max.ru">
                                <span style={{}}>https://lindent-max.ru</span>
                            </a>
                        </span>
                        <span>
                            {" "}
                            , содержащий в себе все технические возможности, необходимые для ознакомления со справочными и
                            информационными ресурсами, осуществления Заказчиком просмотра описаний Услуг и их приобретения
                            (Заказа). Исключительные права на Сайт принадлежат Исполнителю.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            marginBottom: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            <strong>«Услуги»</strong>- информационные услуги по предоставлению Заказчику платного доступа к
                            выбранному Заказчиком Инструменту с целью его самостоятельного изучения и практического
                            применения. Информация об Услугах размещена на Сайте, Канале и Оферте.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            marginBottom: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            <strong>«Социальный инструмент»</strong> («Инструмент») - электронный материал в видеоформате,
                            созданный Исполнителем и содержащий информационный контент о&nbsp; создании, продвижении
                            зарубежных видеороликов на видеохостинге YouTube (
                        </span>
                        <span lang="RU">
                            <a href="https://www.youtube.com">
                                <span style={{}}>https://www.youtube.com</span>
                            </a>
                        </span>
                        <span>), о методиках управления вниманием зрителя, с целью получения прибыли Заказчиком.</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            marginBottom: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            <strong>«Договор»</strong> - договор возмездного оказания Услуг, заключаемый между Исполнителем
                            и Заказчиком путем Акцепта оферты последним, и включающий в себе предоставление Заказчику
                            доступа к Инструменту.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            marginBottom: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            <strong>«Заказ»</strong> - заявка Заказчика на получение Услуг, оформленная через Сайт и
                            представляющая собой свободное и самостоятельное намерение и волеизъявление Заказчика получить
                            их результат (передаваемые Исполнителем Инструменты).
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            marginBottom: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            <strong>«AdSense-аккаунт»</strong> - зарегистрированный Исполнителем аккаунт в сервисе Google,
                            благодаря которому происходит подключение YouTube-канала к монетизации
                            (присвоение YouTube-каналу статуса полноценного участника официальной партнерской программы YouTube).
                        </span>
                    </p>
                    <ol style={{ marginTop: "0.25in" }} start={2}>
                        <li
                            className={styles.MsoNormal}
                            style={{
                                color: "black",
                                marginBottom: "0.1in",
                                textAlign: "center",
                                lineHeight: "normal",
                                border: "none"
                            }}>
                            <b>
                                <span lang="RU" style={{}}>
                                    Предмет Оферты
                                </span>
                            </b>
                        </li>
                    </ol>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            2.1.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Предметом настоящей Оферты является дистанционное оказание Исполнителем возмездных Услуг путем
                            предоставления Заказчику доступа к Инструментам согласно Заказу.{" "}
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            2.2.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            После регистрации Заказчика на Сайте, оформления им Заказа и 100% оплаты Услуг Исполнитель
                            предоставляет Заказчику доступ к Инструменту через личный кабинет Заказчика, расположенный на
                            Сайте.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            2.3.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Услуги включают в себя круглосуточную информационно-консультационную поддержку Заказчика через
                            мессенджер{" "}
                        </span>
                        <span style={{ fontSize: "13.0pt" }}>Telegram</span>
                        <span>
                            , принадлежащий Исполнителю (@manager_lindent), включающий в себя, но не ограничиваясь указанным:
                            ответы на вопросы Заказчика в рамках заказанных Услуг; оказание помощи с контентом Заказчика,
                            размещаемым им на его YouTube-канале; выбор ниши, которую будет снимать Заказчик; корректировка
                            и проработка контента; указание на ошибки, недочеты Заказчика; согласование выпуска новых
                            видеороликов Заказчиком; подсказки, что добавить/убрать в размещаемом Заказчиком видео и пр.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            2.4.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Непосредственную деятельность на своем
                            YouTube-канале заказчик осуществляет с помощью прямого или косвенного участия Исполнителя.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            2.5.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            После получения результата Услуг (доступа Заказчика к Инструменту) и полного прохождения им всех
                            этапов обучения, указанных в Инструментах, Исполнитель гарантирует Заказчику создание Заказчиком
                            собственного{" "}
                        </span>
                        <span>
                            YouTube-канала и дальнейшего{" "}
                            <span style={{ color: "black" }}>получения дохода от 600 долларов США (</span>
                        </span>
                        <span
                            style={{
                                color: "black"
                            }}>
                            USD
                        </span>
                        <span>
                            ) в месяц, при условии неукоснительного соблюдения условий, доводимых Исполнителем до Заказчика
                            посредством Инструмента и/или через мессенджер{" "}
                        </span>
                        <span style={{ fontSize: "13.0pt" }}>Telegram</span>
                        <span>. </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            2.6
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Заказчик в праве воспользоваться дополнительной услугой Исполнителя «Подключение к
                            партнерской программе YouTube». Указанная услуга осуществляется посредством подключения
                            монетизации через AdSense-аккаунт, принадлежащий Исполнителю. Основной целью дополнительной
                            услуги является не получение дохода Заказчиком, а лучшее ранжирование YouTube-канала
                            Заказчика в поиске и системе рекомендаций, и в случае заказа данной услуги Заказчиком,
                            YouTube-канал Заказчика лучше продвигается в видео-хостинге YouTube.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <br />
                        <span>
                            Дополнительная услуга оказывается на следующих условиях:
                        </span>
                        <br />
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            2.6.1
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                        Для сторонних YouTube-каналов Заказчика, которые не находятся в рамках оказания основных Услуг (п. 2.3., 2.4. Оферты), стоимость дополнительной услуги составляет 7 000 руб., оплата осуществляется в порядке 100% предоплаты.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            2.6.2
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                    Для Заказчиков основных Услуг (п. 2.3., 2.4. Оферты), получивших доступ к Инструментам,
                            дополнительная услуга предоставляется безвозмездно (входит в стоимость основных Услуг).
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            2.6.3
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                        Если к YouTube-каналу Заказчика, на котором происходит отработка Инструмента в рамках оказания
                            Услуги (совместная работа над YouTube-каналом, обучение
                            Заказчика созданию популярных роликов), подключена дополнительная услуга Исполнителя,
                            то монетизация с такого YouTube-канала осуществляется полностью в пользу владельца
                            AdSense-аккаунта.
                        </span>
                    </p>
                     <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            2.6.4
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                        Если любой другой YouTube-канал подключается к AdSense-аккаунту, принадлежащему Исполнителю,
                            то монетизация с такого YouTube-канала также осуществляется полностью в пользу владельца
                            AdSense-аккаунта.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            2.6.5
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                        При подключении любых YouTube-каналов к AdSense-аккаунту, принадлежащему Исполнителю,
                            Заказчик безусловно соглашается с тем, что он понимает и принимает цель дополнительной
                            услуги – лучшее ранжирование принадлежащих Заказчику YouTube-каналов в поиске и системе
                            рекомендаций видео-хостинга YouTube без цели извлечения им прибыли.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            2.6.6
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                        В случае возникновения непредвиденных ситуаций, связанных с AdSense-аккаунтом Исполнителя
                            (например, блокировка, приостановка обслуживания, отключение за нарушение правил
                            программы и пр.), Исполнитель обязуется безвозмездно подключить для Заказчика аналогичный
                            AdSense-аккаунт.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            2.7.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Поиск, Заказ, оплата Заказчиком Услуг, размещенных на Сайте, их использование, включая
                            просмотр/пользование размещенной в них информации, материалов, пользование ресурсами Сайта
                            означает согласие Заказчика с настоящей Офертой.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            background: "white",
                            border: "none"
                        }}>
                        <span>
                            2.8.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Исполнитель и Заказчик предоставляют взаимные гарантии своих прав и дееспособности, необходимых
                            для заключения Договора и его исполнения на базе настоящей Оферты.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            background: "white",
                            border: "none"
                        }}>
                        <span>
                            2.9.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>Юридические данные об Исполнителя размещены на страницах Сайта.</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            background: "white",
                            border: "none"
                        }}>
                        <span>
                            2.10.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Оферта не требует скрепления печатями и/или подписания Исполнителем и Заказчиком (далее по
                            тексту – «Стороны») на бумажном носителе, сохраняя при этом полную юридическую силу.{" "}
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            background: "white",
                            border: "none"
                        }}>
                        <span>
                            2.11.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>
                            Заключаемый между Сторонами Договор имеет силу акта об оказании Услуг. Приемка-передача Услуг
                            производится без подписания между Сторонами соответствующих актов. Приемка услуг осуществляется
                            способом, указанным в{" "}
                        </span>
                        <span>п. 4.6.</span>
                        <span>Оферты.</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            background: "white",
                            border: "none"
                        }}>
                        <span>
                            2.12.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>
                            Заказчик уведомлен и согласен с тем, что Услуги носят информационно-консультативный характер, не
                            являются образовательными. Исполнитель не имеет лицензии на осуществление образовательной
                            деятельности.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            2.13.<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>Исполнитель имеет право вносить изменения в Оферту без уведомления Заказчика.</span>
                        <span lang="RU" style={{ color: "black" }}>
                            {" "}
                        </span>
                        <span>
                            Заказчик несет персональную ответственность за проверку настоящей Оферты на наличие изменений в
                            ней.
                        </span>
                        <span lang="RU" style={{ color: "black" }}>
                            {" "}
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            marginTop: "0in",
                            marginRight: "0in",
                            marginBottom: "0in",
                            marginLeft: "70.9pt",
                            textAlign: "justify",
                            lineHeight: "normal",
                            background: "white",
                            border: "none"
                        }}>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </p>
                    <ol style={{ marginTop: "0.25in" }} start={3}>
                        <li
                            className={styles.MsoNormal}
                            style={{
                                color: "black",
                                marginBottom: "0.1in",
                                textAlign: "center",
                                lineHeight: "normal",
                                border: "none"
                            }}>
                            <b>
                                <span lang="RU" style={{}}>
                                    Момент заключения Оферты (акцепт Оферты)
                                </span>
                            </b>
                        </li>
                    </ol>
                    <p
                        className={styles.MsoListParagraph}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            3.1.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Акцептом настоящей Оферты (и как итог – заключение Договора) является Заказ Заказчиком Услуг и
                            их 100% оплата в соответствии с условиями настоящей Оферты.{" "}
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            3.2.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Оферта вступает в силу с момента Акцепта и действует до полного исполнения Сторонами своих
                            обязательств.{" "}
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            3.3.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Осуществляя Акцепт Оферты Заказчик гарантирует, что ознакомлен, соглашается, полностью и
                            безоговорочно принимает все условия Оферты в том виде, в каком они изложены в тексте Оферты (п.
                            3 ст. 438 ГК РФ).{" "}
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            3.4.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Заказчик понимает, что Акцепт Оферты равносилен заключению Договора на условиях, изложенных в
                            Оферте.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            3.5.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Совершая действия по акцепту Оферты Заказчик гарантирует, что он имеет законные права вступать в
                            договорные отношения с Исполнителем.{" "}
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            3.6.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>Акцептируя настоящую Оферту, Заказчик выражает согласие в том, что: </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            background: "white",
                            border: "none"
                        }}>
                        <span>
                            3.6.1.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>данные Заказчика указаны им добровольно;</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            background: "white",
                            border: "none"
                        }}>
                        <span>
                            3.6.2.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>иные данные передаются Исполнителя в электронной форме по каналам связи сети Интернет;</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            background: "white",
                            border: "none"
                        }}>
                        <span>
                            3.6.3.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>данные переданы Исполнителя для реализации целей, указанных в настоящей Оферте;</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            background: "white",
                            border: "none"
                        }}>
                        <span>
                            3.6.4.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>
                            данное Заказчиком согласие на обработку его данных может быть отозвано Заказчиком или его
                            законным представителем, подачей письменного заявления, переданного Исполнителя.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            marginTop: "0in",
                            marginRight: "0in",
                            marginBottom: "0in",
                            marginLeft: "35.45pt",
                            textAlign: "justify",
                            lineHeight: "normal",
                            background: "white",
                            border: "none"
                        }}>
                        <span
                            lang="RU"
                            style={{
                                color: "#1F1F1F"
                            }}>
                            &nbsp;
                        </span>
                    </p>
                    <p
                        className={styles.MsoListParagraphCxSpFirst}
                        style={{
                            marginBottom: "0in",
                            textAlign: "center",
                            textIndent: "-.25in",
                            lineHeight: "normal"
                        }}>
                        <b>
                            <span>
                                4.
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                            </span>
                        </b>
                        <b>
                            <span>Заказ Услуг</span>
                        </b>
                    </p>
                    <p
                        className={styles.MsoListParagraphCxSpMiddle}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            4.1.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Заказ Услуг осуществляется Заказчиком путем отправки соответствующих данных через формы,
                            размещенные на Сайте и в личном кабинете Заказчика после прохождения процедуры регистрации.
                        </span>
                    </p>
                    <p
                        className={styles.MsoListParagraphCxSpMiddle}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            4.2.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            При Заказе Услуг требуется получение от Заказчика следующих данных: имя, номер телефона, адрес
                            электронной почты.
                        </span>
                    </p>
                    <p
                        className={styles.MsoListParagraphCxSpMiddle}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            4.3.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            В момент оформления Заказа и заключения Договора Заказчик подтверждает, что он ознакомился со
                            стоимостью Услуг, способами оплаты и передачи результата Услуг (предоставление доступа к
                            Инструменту).
                        </span>
                    </p>
                    <p
                        className={styles.MsoListParagraphCxSpMiddle}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            4.4.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            После 100% предоплаты Услуг Заказчиком через личный кабинет Заказчика Исполнитель передает
                            доступ к Инструменту.
                        </span>
                    </p>
                    <p
                        className={styles.MsoListParagraphCxSpMiddle}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            4.5.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Исполнитель не несет ответственности за содержание и достоверность информации, предоставленной
                            Заказчиком при оформлении Заказа. Заказчик берет на себя полную ответственность за
                            предоставление неправильных данных о себе, которое сделало невозможным надлежащее исполнение
                            Исполнителем настоящей Оферты.
                        </span>
                    </p>
                    <p
                        className={styles.MsoListParagraphCxSpLast}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            4.6.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Исполнитель считается исполнившим свои обязательства по настоящей Оферте с момента
                            предоставления Заказчику результата Услуг - доступа к Инструменту через личный кабинет
                            Заказчика. Услуги считаются оказанными надлежащим образом и в полном объеме, если в течение 10
                            (десяти) дней с момента окончания оказания Услуг Заказчиком не выставлена претензия Исполнителю.
                            В случае отсутствия претензии акт приемки-сдачи Услуг считается подписанным, а Услуги оказанными
                            надлежащим образом. Стороны после оказания Услуг по Оферте не имеют друг к другу никаких
                            претензий.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            marginBottom: "0in",
                            textAlign: "justify",
                            lineHeight: "normal",
                            background: "white",
                            border: "none"
                        }}>
                        <span
                            lang="RU"
                            style={{
                                color: "#1F1F1F"
                            }}>
                            &nbsp;
                        </span>
                    </p>
                    <ol style={{ marginTop: "0.25in" }} start={5}>
                        <li
                            className={styles.MsoNormal}
                            style={{
                                marginBottom: "0in",
                                textAlign: "center",
                                lineHeight: "normal"
                            }}>
                            <b>
                                <span lang="RU" style={{}}>
                                    Права и обязанности Сторон
                                </span>
                            </b>
                        </li>
                    </ol>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.1.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>Исполнитель обязуется:</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.1.1.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>Принять Заказ и оплату от Заказчика.</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.1.2.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>Предоставлять достоверную информацию об Услугах.</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.1.3.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>На основании Заказа оказать Услугу и передать ее результат.</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.1.4.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>
                            После Заказа Услуг консультировать Заказчика по дополнительным вопросам Заказчика, а также
                            осуществлять последующую информационную поддержку Заказчика.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.1.5.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>Использовать информацию, предоставленную Заказчиком, только для оказания Услуг.</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.1.6.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>Рассматривать и проверять мотивированные претензии, поступающие от Заказчика.</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.2.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>Исполнитель в праве:</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.2.1.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Отказаться от исполнения Договора в одностороннем порядке при нарушении Заказчиком условий,
                            указанных в Оферте.{" "}
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.2.2.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Регулировать доступ к Заказу Услуг, а также приостанавливать или прекращать реализацию любых
                            Услуг по своему собственному усмотрению.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.2.3.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>Изменять условия Оферты в одностороннем порядке.</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.2.4.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>
                            Отказать в оказании Услуг Заказчику в случае не поступления оплаты за Услугу в установленные в
                            Оферте сроки, а также при отсутствии от Заказчика своевременно полученного Заказа или иных
                            действий Заказчика, необходимых для оказания ему Услуги.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.2.5.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>
                            Приостановить работу Сайта в любое время без предварительного уведомления в целях обновления
                            Сайта, проведения профилактических работ, в случае технических сбоев, действием и/или
                            бездействием третьих лиц и по иным причинам, не зависящим от Исполнителя.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.2.6.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>
                            За нарушение условий настоящей Оферты заблокировать доступ Заказчика к Сайту без объяснения
                            причин.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.3.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>Заказчик обязуется:</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.3.1.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>
                            Предварительно знакомиться с информацией об Услугах и условиях их приобретения до оформления
                            Заказа.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.3.2.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>Предоставить при оформлении Заказа только достоверные данные. </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.3.3.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>
                            Действовать добросовестно и не злоупотреблять своими правами, в частности не приобретать Услуги
                            с целью совершения недобросовестных действий, причиняющих ущерб, убытки или затраты для
                            Исполнителя.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.3.4.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>
                            Не использовать информацию, полученную от Исполнителя способами, способными привести к нанесению
                            ущерба интересам Исполнителя.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.3.5.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>
                            Не распространять материалы Сайта, материалы Инструмента любым способом, в т. ч. третьим лицам,
                            не копировать, не сохранять, не размещать, не публиковать в общедоступных, закрытых, открытых
                            источниках для любого круга лиц (в т.ч. для собственного использования) без{" "}
                            <span style={{ color: "black" }}>согласия&nbsp;Исполнителя как правообладателя </span>
                            (статья 1270&nbsp;ГК РФ).
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.3.6.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>Оплатить Услугу в полном объеме.</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.3.7.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>
                            Незамедлительно уведомлять Исполнителя о наличии претензий к Услуге (при наличии таковых).
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.4.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>Заказчик вправе:</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.4.1.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>Отказаться от оплаты Услуг, аннулировав Заказ, о чем немедленно известить Исполнителя. </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.4.2.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>Требовать от Исполнителя надлежащего и своевременного оказания Услуг.</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.4.3.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>Осуществить выбор Услуг.</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            5.4.4.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </span>
                        <span>Обращаться к Исполнителю по всем вопросам, связанным с оказанием Услуг.</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{ marginBottom: "0in", lineHeight: "normal", background: "white" }}>
                        <span
                            lang="RU"
                            style={{
                                color: "#1F1F1F"
                            }}>
                            &nbsp;
                        </span>
                    </p>
                    <ol style={{ marginTop: "0.25in" }} start={6}>
                        <li
                            className={styles.MsoNormal}
                            style={{
                                color: "black",
                                marginBottom: "0.1in",
                                textAlign: "center",
                                lineHeight: "normal",
                                border: "none"
                            }}>
                            <b>
                                <span lang="RU" style={{}}>
                                    Финансовые условия
                                </span>
                            </b>
                        </li>
                    </ol>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            6.1.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Стоимость Услуг определяется Исполнителем самостоятельно и указывается на страницах Сайта.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            6.2.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Исполнитель вправе без объяснения причин и без предварительного уведомления Заказчика в
                            одностороннем порядке изменять стоимость Услуг, путем публикации новой стоимости Услуг на Сайте.
                            При этом стоимость оплаченных Заказчиком Услуг не подлежит изменению.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            background: "white",
                            border: "none"
                        }}>
                        <span>
                            6.3.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>Цена Услуг указывается в рублях РФ. Все взаиморасчеты ведутся в рублях РФ</span>
                        <span>.</span>
                        <span lang="RU" style={{ color: "red" }}>
                            {" "}
                        </span>
                        <span
                            lang="RU"
                            style={{
                                color: "red"
                            }}>
                            НДС не облагается в связи с применением Исполнителем УСН.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            background: "white",
                            border: "none"
                        }}>
                        <span>
                            6.4.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Стоимость Услуг включает в себя все комиссии, взимаемые платежными системами, с использованием
                            которых производится оплата стоимости Услуг Заказчиком.{" "}
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            background: "white",
                            border: "none"
                        }}>
                        <span>
                            6.5.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>Основанием для оплаты стоимости Услуг является оформляемый Заказчиком Заказ. </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            background: "white",
                            border: "none"
                        }}>
                        <span>
                            6.6.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Оплата стоимости Услуг производится Заказчиком на условиях 100 % (стопроцентной) предварительной
                            оплаты в безналичном порядке с использованием платежных систем, представленных в личном кабинете
                            Сайта при оформлении Заказа. При этом Исполнитель в праве запросить, а Заказчик обязан
                            незамедлительно предоставить копии электронных чеков, подтверждающих оплату Услуг Заказчиком.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            background: "white",
                            border: "none"
                        }}>
                        <span>
                            6.7.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>Датой оплаты признается дата поступления средств на расчетный счет Исполнителя.</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            background: "white",
                            border: "none"
                        }}>
                        <span>
                            6.8.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Исполнитель предоставляет Заказчику возможность рассрочки оплаты Услуг. Информация о рассрочке
                            указана на Сайте. Рассрочка предоставляется банком-партнером Исполнителя.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            background: "white",
                            border: "none"
                        }}>
                        <span>
                            6.9.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Заказчик при отказе от Договора не в праве требовать возврата оплаченных им денежных средств,
                            т.к. Исполнитель считается исполнившим свои обязательства по настоящей Оферте с момента
                            предоставления Заказчику доступа к Инструменту.{" "}
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            marginTop: "0in",
                            marginRight: "0in",
                            marginBottom: "0in",
                            marginLeft: "35.45pt",
                            textAlign: "justify",
                            lineHeight: "normal",
                            background: "white",
                            border: "none"
                        }}>
                        <span lang="RU" style={{ color: "black" }}>
                            &nbsp;
                        </span>
                    </p>
                    <ol style={{ marginTop: "0.25in" }} start={7}>
                        <li
                            className={styles.MsoNormal}
                            style={{
                                color: "black",
                                marginBottom: "0.1in",
                                textAlign: "center",
                                lineHeight: "normal",
                                border: "none"
                            }}>
                            <b>
                                <span lang="RU" style={{}}>
                                    Ответственность
                                </span>
                            </b>
                        </li>
                    </ol>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            7.1.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            При неисполнении или ненадлежащем исполнении обязательств Стороны несут ответственность,
                            предусмотренную действующим законодательством РФ.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            7.2.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Исполнитель никак не принуждает Заказчика, не настаивает делать какие-либо действия посредством
                            Средств связи.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            7.3.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>Заказчик признает и соглашается, что:</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            7.3.1.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>Он на добровольной основе производит оплату с целью получения результата Услуг.</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            7.3.2.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>Он несет ответственность за свои действия, совершенные через Сайт, мессенджер </span>
                        <span
                            style={{
                                color: "black"
                            }}>
                            Telegram
                        </span>
                        <span>.</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            7.3.3.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Услуги, приобретаемые Заказчиком, предоставляется по принципу «как есть». При этом Исполнитель
                            не несет ответственность в какой бы то ни было форме за несоответствие результата Услуг целям,
                            задачам, представлениям или желаниям Заказчика.{" "}
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            7.3.4.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Действия и сообщения, полученные от Исполнителя, не являются призывом к действию и призывом к
                            применению.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            7.3.5.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Заказчик осознает всю ответственность по поводу порядка использования Инструмента, передаваемых
                            Исполнителем, и порядка получения доступа к ним.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            7.3.6.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Заказчик осознает всю юридическую ответственность в части правового регулирования использования
                            авторского Инструмента Исполнителя в соответствии с положениями законодательства об авторских и
                            иных смежных правах.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            7.3.7.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Ничто в настоящей Оферте не может гарантировать для Заказчика полное удовлетворение его
                            интересов и потребностей, связанных с приобретением Услуг.{" "}
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            7.4.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>Исполнитель не несет ответственности:</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            7.4.1.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            &nbsp;За убытки и расходы, возникшие у Заказчика, в частности, убытки и расходы, вызванные
                            действиями/бездействием третьих лиц и/или возникшие в связи со сбоями и перерывами в работе
                            Сайта и/или возникшие в связи с воздействием компьютерных вирусов, «троянов», «червей» и т.д.
                            и/или связанные с блокировкой Заказчика, использующего результат Услуг, после ее заказа, вне
                            зависимости от причины блокировки.{" "}
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            7.4.2.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            За неполучение результата Услуг Заказчиком по причинам не зависящим от воли Исполнителя
                            (отсутствие у Заказчика необходимого программного обеспечения, возможности выхода в Интернет,
                            действий провайдеров, энергетических компаний, воздействием компьютерных вирусов и (или)
                            вредоносных программ и т.п.).{" "}
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            7.4.3.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>За практическую применимость и ценность информации, относящуюся к Услугам.</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            7.4.4.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            За действия (бездействие) Заказчика, не зависящие от Исполнителя, в том числе при несоблюдении
                            Заказчиком условий (в целом и/или в части), доводимых Исполнителем до Заказчика посредством
                            Инструмента и/или через мессенджер Telegram.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            7.4.5.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            За неполучение результата Услуг ниже ожиданий Заказчика, поскольку успешность использования
                            Заказчиком полученных знаний, умений и навыков, отраженных в Инструменте, зависит от многих
                            известных и неизвестных Исполнителю факторов, что принимается обеими Сторонами.{" "}
                        </span>
                    </p>
                    <p
                        className={styles.MsoListParagraphCxSpFirst}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            7.5.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Не допускается использовать материалы, включенные в Инструменты для&nbsp; осуществления
                            Заказчиком&nbsp; какой-либо&nbsp; предпринимательской&nbsp; деятельности&nbsp; или&nbsp; любой
                            деятельности, направленной на извлечение дохода, за исключением случаев, прямо указанных в
                            Оферте.
                        </span>
                    </p>
                    <p
                        className={styles.MsoListParagraphCxSpLast}
                        style={{
                            marginTop: "0in",
                            marginRight: "0in",
                            marginBottom: "0in",
                            marginLeft: "70.9pt",
                            textAlign: "justify",
                            lineHeight: "normal"
                        }}>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </p>
                    <ol style={{ marginTop: "0.25in" }} start={8}>
                        <li
                            className={styles.MsoNormal}
                            style={{
                                color: "black",
                                marginBottom: "0.1in",
                                textAlign: "center",
                                lineHeight: "normal"
                            }}>
                            <b>
                                <span lang="RU" style={{}}>
                                    Разрешение споров.{" "}
                                </span>
                            </b>
                        </li>
                    </ol>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            8.1.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Все споры, разногласия и претензии Стороны будут стремиться решить путем переговоров. Сторона, у
                            которой возникли претензии и/или разногласия, направляет другой Стороне сообщение с указанием
                            возникших претензий и/или разногласий.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            8.2.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>Сообщение направляется Заказчиком по электронной почте на адрес </span>
                        <span lang="RU">
                            <a href="mailto:support@lindent-max.ru">
                                <span lang="EN-US" style={{}}>
                                    support
                                </span>
                                <span style={{}}>@lindent-max.ru</span>
                            </a>
                        </span>
                        <span>
                            {" "}
                            или через Средства связи. Сообщение должно содержать суть предъявляемого требования,
                            доказательства, подтверждающие требование.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            8.3.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            В течение 10 (десяти) дней с момента получения оригинала сообщения, Сторона, получившая его,
                            обязана направить ответ на это сообщение.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            8.4.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            В случае, если ответ на сообщение не будет получен направившей сообщение Стороной в течение 30
                            (Тридцати) рабочих дней с даты направления соответствующего сообщения, либо если Стороны не
                            придут к соглашению по возникшим претензиям и/или разногласиям{" "}
                            <span style={{ color: "black" }}>
                                спор подлежит рассмотрению в суде в соответствии с законодательством РФ.
                            </span>
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            8.5.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            За неисполнение или ненадлежащее исполнение обязательств по настоящему Договору, стороны несут
                            ответственность в соответствии с законодательством Российской Федерации.{" "}
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{ marginBottom: "0in", lineHeight: "normal", background: "white" }}>
                        <span
                            lang="RU"
                            style={{
                                color: "#1F1F1F"
                            }}>
                            &nbsp;
                        </span>
                    </p>
                    <ol style={{ marginTop: "0.25in" }} start={9}>
                        <li
                            className={styles.MsoNormal}
                            style={{
                                marginBottom: "0.1in",
                                textAlign: "center",
                                lineHeight: "normal"
                            }}>
                            <b>
                                <span lang="RU" style={{}}>
                                    Срок действия Оферты.
                                </span>
                            </b>
                        </li>
                    </ol>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            9.1.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>Настоящая Оферта вступает в силу с момента ее размещения в сети Интернет на Сайте.</span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            9.2.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Настоящая Оферта размещена на неопределенный срок и утрачивает свою силу при ее аннулировании
                            Исполнителем.{" "}
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            9.3.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Заказчик соглашается и признает, что внесение изменений в Оферту влечет за собой внесение этих
                            изменений в заключенный и действующий между Заказчиком и Исполнителем Договор, и эти изменения
                            вступают в силу одновременно с такими изменениями в Договор.
                        </span>
                    </p>
                    <p className={styles.MsoNormal} style={{ marginBottom: "0in", lineHeight: "normal" }}>
                        <b>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </b>
                    </p>
                    <ol style={{ marginTop: "0.25in" }} start={10}>
                        <li
                            className={styles.MsoNormal}
                            style={{
                                color: "black",
                                marginBottom: "0.1in",
                                textAlign: "center",
                                lineHeight: "normal",
                                border: "none"
                            }}>
                            <b>
                                <span lang="RU" style={{}}>
                                    Дополнительные условия
                                </span>
                            </b>
                        </li>
                    </ol>
                    <p
                        className={styles.MsoListParagraphCxSpFirst}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            10.1.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Все объекты Сайта, в том числе элементы дизайна, текст, графические изображения, видео, другие
                            объекты, а также любой контент, размещенный на Сайте, Канале, в том числе материалы Инструмента,
                            являются объектами исключительных прав Исполнителя и других правообладателей соответственно, и
                            охраняются законодательством об интеллектуальной собственности РФ, а также соответствующими
                            международными конвенциями, соглашениями.
                        </span>
                    </p>
                    <p
                        className={styles.MsoListParagraphCxSpLast}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal"
                        }}>
                        <span>
                            10.2.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Сайт может включать ссылки на веб-ресурсы, принадлежащие или контролируемые третьими лицами.
                            Попадая на такой сайт по ссылке, Заказчик должен прочитать правила использования этого
                            веб-ресурса и согласиться на их соблюдение до начала его использования. Заказчик также
                            соглашается с тем, что Исполнитель не может контролировать содержание такого веб-ресурса и брать
                            на себя какую-либо ответственность за информацию, материалы, программное обеспечение и
                            др.,&nbsp;размещенные на нем. Наименования компаний, изображения и т.д., упомянутые на Сайте,
                            или содержащие ссылки на другие веб-ресурсы могут быть товарными знаками или товарными
                            наименованиями третьих лиц. Доступ Заказчика к Сайту не должен рассматриваться как
                            предоставление разрешения на использование или права собственности на любые знаки, указанные на
                            Сайте, без предварительного письменного согласия Исполнителя или третьего лица, являющегося
                            собственником таких знаков.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            10.3.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Сайт может временно частично или полностью недоступен по причине проведения профилактических,
                            иных работ или по любым другим причинам технического характера. Техническая служба Сайта имеет
                            право периодически проводить необходимые профилактические или иные работы с предварительным
                            уведомлением Заказчика или без такового.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            10.4.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            К отношениям между Заказчиком и Исполнителем применяются положения Российского законодательства.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            margin: "0in",
                            textAlign: "justify",
                            textIndent: "35.45pt",
                            lineHeight: "normal",
                            border: "none"
                        }}>
                        <span>
                            10.5.
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span>
                            Признание судом недействительности какого-либо положения настоящей Оферты не влечет за собой
                            недействительность остальных положений.
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            marginTop: "0in",
                            marginRight: "0in",
                            marginBottom: "0in",
                            marginLeft: "95.45pt",
                            textAlign: "justify",
                            lineHeight: "normal"
                        }}>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </p>
                    <ol style={{ marginTop: "0.25in" }} start={11}>
                        <li
                            className={styles.MsoNormal}
                            style={{
                                marginBottom: "0in",
                                textAlign: "center",
                                lineHeight: "normal"
                            }}>
                            <b>
                                <span lang="RU" style={{}}>
                                    &nbsp; Реквизиты Исполнителя
                                </span>
                            </b>
                        </li>
                    </ol>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            marginTop: "0in",
                            marginRight: "0in",
                            marginBottom: "0in",
                            marginLeft: ".5in",
                            lineHeight: "normal"
                        }}>
                        <span lang="RU" style={{}}>
                            &nbsp;
                        </span>
                    </p>
                    <p className={styles.MsoNormal} style={{ marginBottom: "0in", lineHeight: "normal" }}>
                        <span>ИП УТЮЖ ИЛЬЯ НИКОЛАЕВИЧ </span>
                    </p>
                    <p className={styles.MsoNormal} style={{ marginBottom: "0in", lineHeight: "normal" }}>
                        <span>ОГРНИП 322774600747400 </span>
                    </p>
                    <p className={styles.MsoNormal} style={{ marginBottom: "0in", lineHeight: "normal" }}>
                        <span>ИНН 772864420416</span>
                    </p>
                    <p className={styles.MsoNormal} style={{ marginBottom: "0in", lineHeight: "normal" }}>
                        <span>Эл. почта: </span>
                        <span lang="RU">
                            <a href="mailto:support@lindent-max.ru">
                                <span lang="EN-US" style={{}}>
                                    support
                                </span>
                                <span style={{}}>@lindent-max.ru</span>
                            </a>
                        </span>
                    </p>
                    <p
                        className={styles.MsoNormal}
                        style={{
                            marginTop: "0in",
                            marginRight: "0in",
                            marginBottom: "0in",
                            marginLeft: ".5in",
                            lineHeight: "normal"
                        }}>
                        <span lang="RU" style={{}}>
                            &nbsp;
                        </span>
                    </p>
                </div>
            </div>
        </section>
    );
}
