import { Link } from "react-router-dom";

import { SigninForm } from "forms/signin";

import styles from "./index.module.scss";

export function SigninPage() {
    return (
        <div className={styles.content}>
            <h1>Авторизация</h1>
            <p>
                Нет аккаунта? <Link to="/signup" children="Регистрация" />
            </p>
            <SigninForm />
            <Link to="/restore" children="Забыли пароль ?" />
        </div>
    );
}
