import styles from "./index.module.scss";
import { Button } from "ui/button";
import { useNavigate } from "react-router-dom";

export function VerifyEmailPage() {
    const navigate = useNavigate();
    function handleNav() {
        navigate("/signin");
    }
    return (
        <div className={styles.content}>
            <h2>Ваш email успешно подтвержден</h2>
            <p>Перейдите на страницу авторизации что бы войти в аккаунт.</p>
            <Button children={"Перейти"} onClick={handleNav} />
        </div>
    );
}
