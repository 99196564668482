import { Link } from "react-router-dom";
import cn from "classnames";

import styles from "./index.module.scss";
import { Fragment, useState } from "react";
import { useAppSelector } from "state/store";
import { Modal } from "components/modal";
import { PaymentNeedForm } from "forms/paymentNeed";

const currentRouter: Route[] = [
    {
        path: "/admin/users",
        title: "Панель управления",
        isAdmin: true
    },
    {
        path: "/profile",
        title: "Личный кабинет"
    },
    {
        path: "/categories",
        title: "Видеоролики",
        isPaid: true
    },
    {
        path: "/payment",
        title: "Оплата подписки",
        isPaid: false
    },
    {
        path: "/oferta",
        title: "Договор оферты"
    },
    {
        path: "/politics",
        title: "Политика конфиденциальности"
    }
];

export function Menu({ menuIsActive, setMenuActivity }: Components.Menu) {
    const { user } = useAppSelector((state) => state.core);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    function handleOpener() {
        setIsOpen(true);
    }
    function handleCloser() {
        setMenuActivity((value) => !value);
        setIsOpen(false);
    }
    return (
        <div className={cn(menuIsActive && styles.menu_container_active, styles.menu_container)}>
            <div className={styles.menu_content}>
                <h2 className={styles.menu_title}>МЕНЮ</h2>
                {currentRouter.map(
                    (rputer: Route) =>
                        rputer.isAdmin &&
                        user?.isAdmin && (
                            <Link
                                to={rputer.path}
                                className={styles.menu_link}
                                onClick={() => setMenuActivity((value) => !value)}
                                key={rputer.path}
                                children={
                                    <Fragment>
                                        <div>
                                            <span>{rputer.title}</span>
                                            <span>{rputer.title}</span>
                                        </div>
                                        <p>{"for Admin"}</p>
                                    </Fragment>
                                }
                            />
                        )
                )}
                {currentRouter.map(
                    (rputer: Route, index: number) =>
                        !rputer.isAdmin && (
                            <Link
                                to={rputer.isPaid && !user?.isPaid ? "/payment" : rputer.path}
                                className={styles.menu_link}
                                onClick={
                                    rputer.isPaid && !user?.isPaid ? handleOpener : () => setMenuActivity((value) => !value)
                                }
                                key={rputer.path}
                                children={
                                    <Fragment>
                                        <div>
                                            <span>{rputer.title}</span>
                                            <span>{rputer.title}</span>
                                        </div>
                                        <p>{`0${index}`}</p>
                                    </Fragment>
                                }
                            />
                        )
                )}
                {isOpen && (
                    <Modal
                        closeModal={() => setIsOpen(false)}
                        children={<PaymentNeedForm closeModal={() => handleCloser()} />}
                    />
                )}
            </div>
        </div>
    );
}
