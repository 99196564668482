// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contactInfo_div__qNyVf {\n  width: 360px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n}\n.contactInfo_div__qNyVf p {\n  text-align: left;\n  color: black;\n}\n.contactInfo_div__qNyVf h2 {\n  margin-bottom: 20px;\n  color: black;\n}\n.contactInfo_div__qNyVf button {\n  margin-top: 20px;\n  width: 100px;\n}\n@media screen and (max-width: 400px) {\n  .contactInfo_div__qNyVf {\n    width: 300px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/forms/contactInfo/index.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;AACJ;AAAI;EACI,gBAAA;EACA,YAAA;AAER;AAAI;EACI,mBAAA;EACA,YAAA;AAER;AAAI;EACI,gBAAA;EACA,YAAA;AAER;AAAI;EAlBJ;IAmBQ,YAAA;EAGN;AACF","sourcesContent":[".div {\n    width: 360px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 10px;\n    p {\n        text-align: left;\n        color: black;\n    }\n    h2 {\n        margin-bottom: 20px;\n        color: black;\n    }\n    button {\n        margin-top: 20px;\n        width: 100px;\n    }\n    @media screen and (max-width: 400px){\n        width: 300px;\n    }\n\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"div": "contactInfo_div__qNyVf"
};
export default ___CSS_LOADER_EXPORT___;
