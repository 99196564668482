import { Link } from "react-router-dom";

import styles from "./index.module.scss";

export function NotFoundPage() {
    return (
        <div className={styles.container}>
            <h2 className={styles.title}>
                <span>404</span> - страница не найдена
            </h2>
            <p className={styles.message}>
                Кажется вы заблудились, попробуйте вернуться
                <Link to={"/"} className={styles.link} children={"на главную"} />
            </p>
        </div>
    );
}
