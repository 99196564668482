import styles from "./index.module.scss";

export function Textarea({ name, label, register, errors }: UI.Textarea): JSX.Element {
    return (
        <div className={styles.input_container}>
            <label>{label}</label>
            <textarea placeholder={label} id={name} {...register} />
            {errors[name] && <p className={styles.error_msg}>{errors[name].message as string}</p>}
        </div>
    );
}
