import { useNavigate } from "react-router";

import { Button } from "ui/button";
import { RestoreAccountForm } from "forms/restoreAccount";

import styles from "./index.module.scss";

export function RestoreAccountPage() {
    const navigator = useNavigate();
    return (
        <div className={styles.content}>
            <h2>Восстановление аккаунта</h2>
            <p className={styles.description}>
                Для восстановления необходимо перейти по ссылке отправленной на вашу электронную почту и установить новый
                пароль
            </p>
            <RestoreAccountForm />
            <Button className={styles.back} onClick={() => navigator(-1)}>
                Назад
            </Button>
        </div>
    );
}
