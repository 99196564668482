import { Link } from "react-router-dom";
import styles from "./index.module.scss";
import { useState } from "react";
import { Modal } from "components/modal";
import { ContactModal } from "forms/contactInfo";

export function Footer() {
    const [contact, setContact] = useState<boolean>();
    return (
        <footer className={styles.footer}>
            <div className={styles.copyright}>&#169; lindent-max.ru 2023</div>
            <div className={styles.row}>
                <Link to={"oferta"} target="_blank" className={styles.link}>
                    Договор оферты
                </Link>
                <a onClick={() => setContact(true)}>Контакты</a>
                <Link to={"politics"} target="_blank" className={styles.link}>
                    Политика конфиденциальности
                </Link>
            </div>
            <div className={styles.data}>
                <p className={styles.ip}>ИП Утюж Илья Николаевич</p>
                <p className={styles.ogrn}>ОГРН 322774600747400</p>
                <p className={styles.inn}>ИНН 772864420416</p>
            </div>
            {contact && (
                <Modal
                    closeModal={() => setContact(false)}
                    children={<ContactModal closeModal={() => setContact(false)} />}
                />
            )}
        </footer>
    );
}
