// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".textarea_input_container__F2mnV {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n}\n.textarea_input_container__F2mnV textarea {\n  resize: none;\n  height: 300px !important;\n  outline: 0;\n  position: relative;\n  font-size: 1em;\n  background-color: none;\n  height: 40px;\n  border: none;\n  border-bottom: 2px solid #f01932;\n  padding: 4px;\n  width: calc(100% - 8px);\n  margin-bottom: 18px;\n}\n.textarea_input_container__F2mnV .textarea_error_msg__zNdG3 {\n  position: absolute;\n  bottom: 0px;\n  font-size: 14px;\n  color: red;\n}", "",{"version":3,"sources":["webpack://./src/ui/textarea/index.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,sBAAA;AACJ;AAAI;EACI,YAAA;EACA,wBAAA;EACA,UAAA;EACA,kBAAA;EACA,cAAA;EACA,sBAAA;EACA,YAAA;EACA,YAAA;EACA,gCAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;AAER;AAAI;EACI,kBAAA;EACA,WAAA;EACA,eAAA;EACA,UAAA;AAER","sourcesContent":[".input_container {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    textarea {\n        resize: none;\n        height: 300px !important;\n        outline: 0;\n        position: relative;\n        font-size: 1em;\n        background-color:none;\n        height: 40px;\n        border: none;\n        border-bottom: 2px solid #f01932;\n        padding: 4px;\n        width: calc(100% - 8px);\n        margin-bottom: 18px;\n    }\n    .error_msg {\n        position: absolute;\n        bottom: 0px;\n        font-size: 14px;\n        color: red;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input_container": "textarea_input_container__F2mnV",
	"error_msg": "textarea_error_msg__zNdG3"
};
export default ___CSS_LOADER_EXPORT___;
