// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input_input_container__jZEi\\+ {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n}\n.input_input_container__jZEi\\+ .input_field__INn12 {\n  display: flex;\n  gap: 6px;\n  margin-bottom: 20px;\n  border-bottom: 2px solid #f01932;\n}\n.input_input_container__jZEi\\+ .input_field__INn12 input {\n  outline: 0;\n  position: relative;\n  font-size: 1em;\n  background-color: none;\n  height: 40px;\n  border: none;\n  width: 100%;\n}\n.input_input_container__jZEi\\+ .input_field__INn12 .input_action__5EfQl {\n  height: 40px;\n  background-color: transparent;\n}\n.input_input_container__jZEi\\+ .input_error_msg__ijZBA {\n  position: absolute;\n  bottom: 0px;\n  font-size: 14px;\n  color: red;\n}", "",{"version":3,"sources":["webpack://./src/ui/input/index.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,sBAAA;AACJ;AAAI;EAcI,aAAA;EACA,QAAA;EACA,mBAAA;EACA,gCAAA;AAXR;AALQ;EACI,UAAA;EACA,kBAAA;EACA,cAAA;EACA,sBAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;AAOZ;AALQ;EACI,YAAA;EACA,6BAAA;AAOZ;AAAI;EACI,kBAAA;EACA,WAAA;EACA,eAAA;EACA,UAAA;AAER","sourcesContent":[".input_container {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    .field {\n        input {\n            outline: 0;\n            position: relative;\n            font-size: 1em;\n            background-color:none;\n            height: 40px;\n            border: none;\n            width: 100%;\n        }\n        .action {\n            height: 40px;\n            background-color: transparent;\n        }\n        display: flex;\n        gap: 6px;\n        margin-bottom: 20px;\n        border-bottom: 2px solid #f01932;\n    }\n    .error_msg {\n        position: absolute;\n        bottom: 0px;\n        font-size: 14px;\n        color: red;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input_container": "input_input_container__jZEi+",
	"field": "input_field__INn12",
	"action": "input_action__5EfQl",
	"error_msg": "input_error_msg__ijZBA"
};
export default ___CSS_LOADER_EXPORT___;
