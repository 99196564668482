// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main_container__q06V6 {\n  position: relative;\n  max-width: 1400px;\n  margin-inline: 1.5rem;\n}\n@media screen and (min-width: 1400px) {\n  .main_container__q06V6 {\n    margin: 0 auto;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/common/main/index.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,iBAAA;EACA,qBAAA;AACJ;AACI;EALJ;IAMQ,cAAA;EAEN;AACF","sourcesContent":[".container {\n    position: relative;\n    max-width: 1400px;\n    margin-inline: 1.5rem;\n\n    @media screen and (min-width: 1400px) {\n        margin: 0 auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "main_container__q06V6"
};
export default ___CSS_LOADER_EXPORT___;
