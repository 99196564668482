import { Button } from "ui/button";
import { useActions } from "state/store";

import styles from "./index.module.scss";

export function ContactModal({ closeModal }: { closeModal: () => void }) {
    function onSubmit() {
        closeModal();
    }

    return (
        <div className={styles.div}>
            <h2>Контакты</h2>
            <p> support@lindent-max.ru </p>
            <p> +79936108863 </p>
            <p> г. Москва ул. Инессы Арманд д.7 </p>
            <Button onClick={onSubmit} type="button">
                Ок
            </Button>
        </div>
    );
}
