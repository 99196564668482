import { useState } from "react";

import styles from "./index.module.scss";

export function TakeSelector({
    options,
    value,
    setValue
}: {
    options: string[];
    value: string;
    setValue: (value: string) => void;
}) {
    const [isOpen, setIsOpen] = useState(false);

    function toggleMenu() {
        setIsOpen(!isOpen);
    }

    function optionClickHandler(option: string) {
        setValue(option);
        setIsOpen(false);
    }

    return (
        <div className={styles.dropdown}>
            <button className={styles.selected} onClick={toggleMenu}>
                {value}
            </button>
            {isOpen && (
                <ul className={styles.options}>
                    {options.map(
                        (option) =>
                            option !== value && (
                                <li key={option} onClick={() => optionClickHandler(option)}>
                                    {option}
                                </li>
                            )
                    )}
                </ul>
            )}
        </div>
    );
}
