import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

import { useActions, useAppSelector } from "state/store";
import { Button } from "ui/button";
import { Modal } from "components/modal";
import { AdminPaymentForm } from "forms/adminPayment";

import styles from "./index.module.scss";
import { Input } from "ui/input";
import { FieldValues, useForm } from "react-hook-form";
import { TakeSelector } from "ui/takeSelector";
import { RadioSet } from "ui/radioSet/radioSet";
import { AdminDeleteForm } from "forms/adminDelete";

export function UsersPanelPage() {
    const headers = ["Никнейм", "Почта", "Статус оплаты", "Статус бана", "Управление оплатой", "Удаление"],
        posibleTakes = ["10", "20", "50", "100"],
        radioSetOptions = [
            { label: "Все аккаунты", value: "all" },
            { label: "Только верифицированные", value: "verified" },
            { label: "Только забаненные", value: "banned" }
        ];

    const { users, count } = useAppSelector((state) => state.users);
    const { getAllUsers, searchUsers } = useActions();
    const [skip, setSkip] = useState<number>(0);
    const [take, setTake] = useState<string>(localStorage.getItem("users_take") ?? "10");
    const [paymentOpen, setPaymentOpen] = useState<boolean>(false);
    const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
    const [selecteduser, setSelecteduser] = useState<User>();

    const [selectedValue, setSelectedValue] = useState<string>("all");

    function handleRadioChange(value: string) {
        setSelectedValue(value);
    }

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({ mode: "onChange" });

    useEffect(() => {
        getAllUsers({ skip: String(skip), take: String(take) });
    }, [getAllUsers, skip, take]);

    function handlePageClick(event: { selected: number }) {
        const newOffset = (event.selected * 9) % count;
        setSkip(newOffset);
    }

    function setPaymentHendlaer(user: User) {
        setPaymentOpen(true);
        setSelecteduser(user);
    }

    function setDeleteHendlaer(user: User) {
        setDeleteOpen(true);
        setSelecteduser(user);
    }

    function onSubmit(data: FieldValues) {
        searchUsers({ pattern: data.pattern, form: { skip: String(skip), take: String(take) } });
    }

    function setTakeHandler(newTake: string) {
        setTake(newTake);
        localStorage.setItem("users_take", newTake);
    }

    return (
        <div className={styles.content}>
            <div className={styles.control_panel}>
                <form className={styles.search} onSubmit={handleSubmit(onSubmit)}>
                    <Input
                        name="pattern"
                        label="Поиск"
                        type="text"
                        errors={errors}
                        register={register("pattern")}
                        onlyInput
                    />
                    <Button type="submit" children={"Поиск"} />
                </form>
                <TakeSelector value={take} setValue={setTakeHandler} options={posibleTakes} />
            </div>

            <RadioSet options={radioSetOptions} initialValue={selectedValue} onChange={handleRadioChange} />
            <table className={styles.table}>
                <thead>
                    <tr>
                        {headers.map((header) => (
                            <th key={header}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {users.map((user: User) => (
                        <tr key={user.id}>
                            {Object.keys(user).map(
                                (key) =>
                                    key !== "id" && (
                                        <td key={key} className={styles[key]}>
                                            {key === "isPaid" ? (
                                                user[key as keyof User] ? (
                                                    <p className={styles.green}>Оплачено</p>
                                                ) : (
                                                    <p className={styles.red}>Не оплачено</p>
                                                )
                                            ) : key === "isBanned" ? (
                                                user[key as keyof User] ? (
                                                    <p className={styles.red}>Забанен</p>
                                                ) : (
                                                    <p className={styles.green}>Не забанен</p>
                                                )
                                            ) : (
                                                user[key as keyof User]
                                            )}
                                        </td>
                                    )
                            )}
                            <td>
                                <Button
                                    onClick={() => setPaymentHendlaer(user)}
                                    className={styles.btn}
                                    children={"Изменить"}
                                />
                            </td>
                            <td>
                                <Button
                                    onClick={() => setDeleteHendlaer(user)}
                                    className={styles.btn}
                                    children={"Удалить"}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageCount={Math.ceil(count / Number(take))}
                previousLabel="<"
                renderOnZeroPageCount={null}
                containerClassName={styles.pagination}
                previousLinkClassName={styles.prev}
                nextLinkClassName={styles.next}
                pageLinkClassName={styles.page}
                activeLinkClassName={styles.active}
            />
            {paymentOpen && (
                <Modal
                    closeModal={() => setPaymentOpen(false)}
                    children={<AdminPaymentForm closeModal={() => setPaymentOpen(false)} user={selecteduser as User} />}
                />
            )}
            {deleteOpen && (
                <Modal
                    closeModal={() => setDeleteOpen(false)}
                    children={<AdminDeleteForm closeModal={() => setDeleteOpen(false)} user={selecteduser as User} />}
                />
            )}
        </div>
    );
}

// user[key as keyof User] ? (
//     <Button className={styles.btn} children={"Разбанить"} />
// ) : (
//     <Button className={styles.btn} children={"Забанить"} />
// )
