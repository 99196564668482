import { Button } from "ui/button";
import { useActions } from "state/store";

import styles from "./index.module.scss";

export function AdminDeleteForm({ user, closeModal }: { user: User; closeModal: () => void }) {
    const { deleteUser } = useActions();

    function onSubmit() {
        closeModal();
        deleteUser(user.id);
    }

    return (
        <form className={styles.form}>
            <h2>Удаление пользователя</h2>
            <p>Вы действительно удалить пользователя? Это действие необратимо:</p>
            <span>{user.email}</span>
            <Button onClick={onSubmit} type="button">
                Удалить
            </Button>
        </form>
    );
}
