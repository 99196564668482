import { useForm } from "react-hook-form";

import { Input } from "ui/input";
import { Button } from "ui/button";
import { useActions } from "state/store";

import styles from "./index.module.scss";
import { Textarea } from "ui/textarea";

export function CreateCategory({ closeModal }: { category: Category; closeModal: () => void }) {
    const { createCategory } = useActions();

    const {
        register,
        formState: { errors, isValid },
        handleSubmit
    } = useForm({ mode: "onChange" });

    function onSubmit(data: any) {
        createCategory(data);
        closeModal();
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <h2>Создание категории</h2>
            <Input
                name="title"
                label="Название категории"
                type="text"
                errors={errors}
                register={register("title", {
                    required: "Название категории обязательно для заполнения",
                    minLength: {
                        value: 3,
                        message: "Название категории слишком короткое"
                    },
                    maxLength: {
                        value: 50,
                        message: "Название категории слишком длинное"
                    }
                })}
            />
            <Textarea
                name="description"
                label="Описание категории"
                errors={errors}
                register={register("description", {
                    required: "Описание категории обязательно для заполнения",
                    maxLength: {
                        value: 999,
                        message: "Описание категории слишком длинное"
                    }
                })}
            />
            <Button type="submit" disabled={!isValid}>
                Создать
            </Button>
        </form>
    );
}
