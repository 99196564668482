import { Button } from "ui/button";
import { useActions } from "state/store";

import styles from "./index.module.scss";

export function AdminPaymentForm({ user, closeModal }: { user: User; closeModal: () => void }) {
    const { setPayment } = useActions();

    function onSubmit() {
        closeModal();
        setPayment(user.id);
    }

    return (
        <form className={styles.form}>
            <h2>Выдача прав доступа</h2>
            <p>Вы действительно хотите выдать права для просмотра контента пользователю:</p>
            <span>{user.email}</span>
            <Button onClick={onSubmit} type="button">
                Выдать
            </Button>
        </form>
    );
}
