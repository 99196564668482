import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { ApiService } from "axios.api";

export const getAllCategories = createAsyncThunk<[Category[], number], PaginationForm>(
    "category/getAll",
    async function (form, thunkAPI) {
        try {
            return await ApiService.get("/videos/categories", {
                params: form
            });
        } catch (error) {
            const message = ((error as AxiosError).response?.data as Error).message;
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getCategory = createAsyncThunk<Category, string>("videos/get", async function (id, thunkAPI) {
    try {
        return await ApiService.get(`/videos/category/${id}`);
    } catch (error) {
        const message = ((error as AxiosError).response?.data as Error).message;
        return thunkAPI.rejectWithValue(message);
    }
});

export const createCategory = createAsyncThunk<Category, CategoriesAPI.CreateCategoryForm, { rejectValue: string }>(
    "category/create",
    async function (form, thunkAPI) {
        try {
            const res = await ApiService.post("/videos/category", form);
            toast.success("Категория успешно создана");
            return res;
        } catch (error) {
            const message = ((error as AxiosError).response?.data as Error).message;
            toast.error(message);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const updateCategory = createAsyncThunk<
    Category,
    { form: CategoriesAPI.UpdateCategoryForm; id: string },
    { rejectValue: string }
>("category/update", async function (data, thunkAPI) {
    try {
        const res = await ApiService.patch(`/videos/category/${data.id}`, data.form);
        toast.success("Категория успешно обновлена");
        return res;
    } catch (error) {
        const message = ((error as AxiosError).response?.data as Error).message;
        toast.error(message);
        return thunkAPI.rejectWithValue(message);
    }
});

export const deleteCategory = createAsyncThunk<string, string, { rejectValue: string }>(
    "category/delete",
    async function (id, thunkAPI) {
        try {
            await ApiService.delete(`/videos/category/${id}`);
            toast.success("Категория успешно удалена");
            return id;
        } catch (error) {
            const message = ((error as AxiosError).response?.data as Error).message;
            toast.error(message);
            return thunkAPI.rejectWithValue(message);
        }
    }
);
