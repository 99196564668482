import { useMemo } from "react";
import { bindActionCreators } from "redux";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { combineReducers, configureStore } from "@reduxjs/toolkit";

import * as coreActions from "./core/actions";
import * as categoriesActions from "./categories/actions";
import * as videosActions from "./videos/actions";
import * as usersActions from "./users/actions";
import * as politicsActions from "./politics/actions";
import { coreSlice } from "./core/slice";
import { categoriesSlice } from "./categories/slice";
import { videosSlice } from "./videos/slice";
import { usersSlice } from "./users/slice";
import { politicsSlice } from "./politics/slice";

export const combineActions = {
    ...coreActions,
    ...categoriesActions,
    ...videosActions,
    ...usersActions,
    ...politicsActions
};

export const store = configureStore({
    reducer: combineReducers({
        core: coreSlice.reducer,
        categories: categoriesSlice.reducer,
        videos: videosSlice.reducer,
        users: usersSlice.reducer,
        politics: politicsSlice.reducer
    })
});

export function useAppDispatch() {
    return useDispatch<RTK.AppDispatch>();
}

export const useAppSelector: TypedUseSelectorHook<RTK.RootState> = useSelector;

export const useActions = () => {
    const dispatch = useDispatch();
    return useMemo(() => bindActionCreators(combineActions, dispatch), [dispatch]);
};
