import { Button } from "ui/button";
import { useActions } from "state/store";

import styles from "./index.module.scss";

export function SslForm({ closeModal }: { closeModal: () => void }) {
    function onSubmit() {
        closeModal();
    }

    return (
        <div className={styles.div}>
            <h2>Оплата Банковской картой On-Line</h2>
            <p>
                При оформлении заказа выберите способ оплаты "Банковской картой". По окончании оформления заказа вам будет
                доступна форма оплаты.Использовать для оплаты разрешается только вашу личную банковскую карту. Все действия
                с персональными данными осуществляются при помощи защищенного канала на сервере платежной системы. В случае,
                если осуществить платеж не удается, свяжитесь, пожалуйста, с представителем вашего банка для выяснения
                причины отказа в платеже.
            </p>
            <p>
                ⦁ Для платежа используйте только вашу карту. Платежи выполненные при помощи банковской карты не
                принадлежащей вам приняты не будут.
            </p>
            <p>
                ⦁ Для оплаты заказов могут быть использованы карты VISA, MASTERCARD, МИР Российских банков. Платежи c карт
                эмитированных зарубежными банками не принимаются.
            </p>
            <Button onClick={onSubmit} type="button">
                Ок
            </Button>
        </div>
    );
}
