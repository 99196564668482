import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { useActions, useAppSelector } from "state/store";
import { Button } from "ui/button";

import styles from "./index.module.scss";

export function VideoPage() {
    const navigator = useNavigate();
    const { pathname } = useLocation();
    const { video } = useAppSelector((state) => state.videos);
    const { getVideo } = useActions();
    const id = `${pathname.split("/").at(-1)}`;

    useEffect(() => {
        getVideo(id as string);
    }, [pathname, getVideo]);

    return (
        <div className={styles.container}>
            <Button className={styles.back} onClick={() => navigator(-1)} children={"Вернуться назад"} />
            <div className={styles.video__content}>
                <h2 className={styles.video__title}>{video?.title}</h2>
                <video className={styles.video__player} controls controlsList="nodownload">
                    <source src={`https://api.lindent-max.ru/videos/file?id=${id}`} type="video/mp4" />
                </video>
                <p className={styles.video__desc}>{video?.description}</p>
            </div>
        </div>
    );
}
