import { useNavigate } from "react-router";
import { useForm, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";

import { Input } from "ui/input";
import { Button } from "ui/button";
import { Checkbox } from "ui/checkbox";
import { useActions, useAppSelector } from "state/store";

import { Link } from "react-router-dom";
import styles from "./index.module.scss";

export function SignupForm() {
    const navigator = useNavigate();
    const { isSuccess } = useAppSelector((state) => state.core);
    const { signup } = useActions();
    const {
        register,
        formState: { errors, isValid },
        handleSubmit,
        control
    } = useForm({ mode: "onChange" });

    const passwordWatch = useWatch({
        control,
        name: "password"
    });

    function onSubmit(data: any) {
        delete data.confirm_password;
        delete data.agreement;
        signup(data);
    }

    const [style, setStyle] = useState(false);

    useEffect(() => {
        isSuccess && navigator("/signin");
    }, [isSuccess, navigator]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <Input
                name="username"
                label="Имя пользователя"
                type="text"
                errors={errors}
                register={register("username", {
                    required: "Это поле обязательно для заполнения",
                    minLength: {
                        value: 3,
                        message: "Имя пользователя слишком короткое"
                    },
                    maxLength: {
                        value: 28,
                        message: "Имя пользователя слишком длинное"
                    },
                    pattern: {
                        value: /^[a-zа-яё0-9]+$/i,
                        message: "Только буквы и числа"
                    }
                })}
            />
            <Input
                name="email"
                label="Электронная почта"
                type="text"
                errors={errors}
                register={register("email", {
                    required: "Электронная почта обязательна для заполнения",
                    pattern: {
                        value: /^[^.](?=[a-z\d!#$%&'*+\-\\/=?.^_`{}|~]+@([a-z-\d]+\.){1,2}[a-z]{2,}$)((?!\.\.).)*$/i,
                        message: "Некорректный Email"
                    }
                })}
            />
            <Input
                name="password"
                label="Пароль"
                type="password"
                errors={errors}
                register={register("password", {
                    required: "Пароль обязателен для заполнения",
                    pattern: {
                        value: /^(?=.*[a-zA-Z])(?=.*\d).{6,}$/g,
                        message: "Пароль слишком простой"
                    }
                })}
            />
            <Input
                name="confirm_password"
                label="Повторите пароль"
                type="password"
                errors={errors}
                register={register("confirm_password", {
                    required: "Это поле обязательно для заполнения",
                    validate: (value) => value === passwordWatch || "Пароли не совпадают"
                })}
            />
            <div className={styles.agreement}>
                <Checkbox
                    name="agreement"
                    errors={errors}
                    register={register("agreement", {
                        required: "Соглашение обязательно"
                    })}
                />
                <p>
                    {" "}
                    Я согласен с условиями{" "}
                    <Link to={"/politics"} target="_blank">
                        политики обработки персональных данных
                    </Link>{" "}
                </p>
            </div>
            <Button type="submit">Зарегистрироваться</Button>
        </form>
    );
}
