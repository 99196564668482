// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oferta_section__6jq3\\+ {\n  margin-top: 4rem;\n}\n.oferta_section__6jq3\\+ .oferta_container__0JIJ0 {\n  max-width: 800px;\n  margin-inline: 1.5rem;\n}\n.oferta_section__6jq3\\+ .oferta_container__0JIJ0 h3, .oferta_section__6jq3\\+ .oferta_container__0JIJ0 h4 {\n  text-align: center;\n}\n.oferta_section__6jq3\\+ .oferta_container__0JIJ0 .oferta_oferta__title__de9iC {\n  margin-bottom: 1rem;\n}\n@media screen and (min-width: 1000px) {\n  .oferta_section__6jq3\\+ .oferta_container__0JIJ0 {\n    margin: 0 auto;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/common/oferta/oferta.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AACE;EACE,gBAAA;EACA,qBAAA;AACJ;AACI;EACE,kBAAA;AACN;AACI;EACE,mBAAA;AACN;AACI;EAVF;IAWI,cAAA;EAEJ;AACF","sourcesContent":[".section {\n  margin-top: 4rem;\n\n  .container {\n    max-width: 800px;\n    margin-inline: 1.5rem;\n\n    h3, h4 {\n      text-align: center;\n    }\n    .oferta__title {\n      margin-bottom: 1rem;\n    }\n    @media screen and (min-width: 1000px) {\n      margin: 0 auto;\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "oferta_section__6jq3+",
	"container": "oferta_container__0JIJ0",
	"oferta__title": "oferta_oferta__title__de9iC"
};
export default ___CSS_LOADER_EXPORT___;
