import { Button } from "ui/button";
import { useActions } from "state/store";

import styles from "./index.module.scss";

export function DeleteCategory({ category, closeModal }: { category: Category; closeModal: () => void }) {
    const { deleteCategory } = useActions();

    function onSubmit() {
        deleteCategory(category.id);
        closeModal();
    }

    return (
        <form className={styles.form}>
            <h2>Удаление категории</h2>
            <p>
                Вы действительно хотите удалить категорию: <span>{category.title}</span>
            </p>
            <Button onClick={onSubmit} type="button">
                Удалить
            </Button>
        </form>
    );
}
