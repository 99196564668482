import { useForm } from "react-hook-form";
import { useLocation } from "react-router";

import { Input } from "ui/input";
import { Button } from "ui/button";
import { useActions } from "state/store";

import styles from "./index.module.scss";
import { FileInput } from "ui/fileInput";
import { Textarea } from "ui/textarea";

export function CreateVideo({ closeModal }: { video: Video; closeModal: () => void }) {
    const { createVideo } = useActions();
    const { pathname } = useLocation();

    const {
        register,
        formState: { errors, isValid },
        handleSubmit
    } = useForm({ mode: "onChange" });

    function onSubmit(data: any) {
        const form = new FormData();
        form.append("video", data.video[0]);
        form.append("title", data.title);
        form.append("description", data.description);
        createVideo({ form, id: pathname.split("/").at(-1) as string });
        closeModal();
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <h2>Создание видеоролика</h2>
            <Input
                name="title"
                label="Название видеоролика"
                type="text"
                errors={errors}
                register={register("title", {
                    required: "Это поле обязательно для заполнения",
                    minLength: {
                        value: 3,
                        message: "Название видеоролика слишком короткое"
                    },
                    maxLength: {
                        value: 50,
                        message: "Название видеоролика слишком длинное"
                    }
                })}
            />
            <Textarea
                name="description"
                label="Описание видеоролика"
                errors={errors}
                register={register("description", {
                    required: "Это поле обязательно для заполнения",
                    maxLength: {
                        value: 999,
                        message: "Описание видеоролика слишком длинное"
                    }
                })}
            />
            <FileInput
                name="video"
                label="Видеоролик"
                type="file"
                accept="video/*"
                errors={errors}
                register={register("video", {
                    required: "Это поле обязательно для заполнения"
                })}
            />
            <Button type="submit" disabled={!isValid}>
                Создать
            </Button>
        </form>
    );
}
