import { Button } from "ui/button";

import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";

export function PaymentNeedForm({ closeModal }: { closeModal: () => void }) {
    const navigate = useNavigate();
    function onSubmit() {
        navigate("/payment");
        closeModal();
    }
    return (
        <form className={styles.form}>
            <h2>Доступ не оплачен</h2>
            <p>Перейдите на страницу оплаты и оплатите инструменты</p>
            <Button onClick={onSubmit} type="button">
                Оплатить
            </Button>
        </form>
    );
}
