import { useState } from "react";

import ShowPassword from "assets/icon/show-password.svg";
import HidePassword from "assets/icon/hide-password.svg";

import styles from "./index.module.scss";

export function Input({ name, label, register, errors, type, onlyInput }: UI.Input): JSX.Element {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <div className={styles.input_container}>
            <div className={styles.field}>
                <input type={showPassword ? "text" : type} placeholder={label} autoComplete="off" id={name} {...register} />
                {type === "password" && (
                    <button className={styles.action} type="button" onClick={() => setShowPassword(!showPassword)}>
                        <img src={showPassword ? ShowPassword : HidePassword} alt="password-icon" />
                    </button>
                )}
            </div>
            {!onlyInput && errors[name] && <p className={styles.error_msg}>{errors[name].message as string}</p>}
        </div>
    );
}
