// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".resetPassword_content__GuTsJ {\n  width: 100%;\n  min-height: calc(100vh - 30px);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 20px;\n}", "",{"version":3,"sources":["webpack://./src/pages/auth/resetPassword/index.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,8BAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AACJ","sourcesContent":[".content {\n    width: 100%;\n    min-height: calc(100vh - 30px);\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "resetPassword_content__GuTsJ"
};
export default ___CSS_LOADER_EXPORT___;
