import { useLocation } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { createElement, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

import { useActions, useAppSelector } from "state/store";
import { Button } from "ui/button";
import { Modal } from "components/modal";
import { CreateVideo } from "forms/create/video";
import { EditVideo } from "forms/edit/video";

import styles from "./index.module.scss";
import { DeleteVideo } from "forms/delete/video";
import { Video } from "pages/common/main/components/video";

const forms: Record<ModalForm, (args: { video: Video; closeModal: () => void }) => JSX.Element> = {
    create: CreateVideo,
    update: EditVideo,
    delete: DeleteVideo
};

export function CategoryPage() {
    const navigator = useNavigate();
    const { pathname } = useLocation();
    const { user } = useAppSelector((state) => state.core);
    const { videos, count } = useAppSelector((state) => state.videos);
    const { category } = useAppSelector((state) => state.categories);
    const { getAllVideos, deleteVideo, getCategory } = useActions();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [modalType, setModalType] = useState<ModalForm>("create");
    const [currentVideo, setCurrentVideo] = useState<Video>(videos[0]);
    const [skip, setSkip] = useState(0);

    useEffect(() => {
        const id = pathname.split("/").at(-1) as string;
        getCategory(id);
        getAllVideos({ id, form: { skip: `${skip}`, take: "9" } });
    }, [pathname, getAllVideos, getCategory, skip]);

    function createHandler() {
        setIsOpen(true);
        setModalType("create");
    }
    // eslint-disable-next-line no-console
    // console.log(videos);
    function editHandler(video: Video) {
        setIsOpen(true);
        setModalType("update");
        setCurrentVideo(video);
    }

    function deletehandler(video: Video) {
        setIsOpen(true);
        setModalType("delete");
        setCurrentVideo(video);
    }

    function handlePageClick(event: { selected: number }) {
        const offset = (event.selected * 9) % count;
        setSkip(offset);
    }

    return (
        <div className={styles.container}>
            <h2>{category?.title}</h2>
            <p>{category?.description}</p>
            <div className={styles.btns}>
                <Button onClick={() => navigator(-1)} children={"Вернуться назад"} />
                {user?.isAdmin && <Button onClick={createHandler} children={"Создать видеоролик"} />}
            </div>
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={Math.ceil(count / 9)}
                previousLabel="<"
                renderOnZeroPageCount={null}
                containerClassName={styles.pagination}
                previousLinkClassName={styles.prev}
                nextLinkClassName={styles.next}
                pageLinkClassName={styles.page}
                activeLinkClassName={styles.active}
            />
            <div className={styles.grid}>
                {videos.length ? (
                    videos.length === 1 ? (
                        <div key={videos[0].id} className={styles.category}>
                            <h3>{videos[0].title}</h3>
                            <div>
                                <Link to={`/video/${videos[0].id}`} children={<Button children={"Смотреть"} />} />
                                {user?.isAdmin && <Button onClick={() => editHandler(videos[0])} children={"Изменить"} />}
                                {user?.isAdmin && <Button onClick={() => deletehandler(videos[0])} children={"Удалить"} />}
                            </div>
                        </div>
                    ) : (
                        videos.map((video: Video) => (
                            <div key={video.id} className={styles.category}>
                                <h3>{video.title}</h3>
                                <div>
                                    <Link to={`/video/${video.id}`} children={<Button children={"Смотреть"} />} />
                                    {user?.isAdmin && <Button onClick={() => editHandler(video)} children={"Изменить"} />}
                                    {user?.isAdmin && <Button onClick={() => deletehandler(video)} children={"Удалить"} />}
                                </div>
                            </div>
                        ))
                    )
                ) : (
                    <p>В категории нет видеороликов</p>
                )}
            </div>
            {isOpen && (
                <Modal
                    closeModal={() => setIsOpen(false)}
                    children={createElement(forms[modalType], { video: currentVideo, closeModal: () => setIsOpen(false) })}
                />
            )}
        </div>
    );
}
