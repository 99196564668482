// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".radioSet_set__y5S1g {\n  display: flex;\n  gap: 16px;\n}\n.radioSet_set__y5S1g label {\n  display: flex;\n  gap: 6px;\n  cursor: pointer;\n}\n.radioSet_set__y5S1g label input:after {\n  content: \"\";\n  left: -2px;\n  top: 2px;\n  width: 16px;\n  height: 16px;\n  border-radius: 16px;\n  position: relative;\n  background-color: #d1d3d1;\n  display: inline-block;\n  visibility: visible;\n}\n.radioSet_set__y5S1g label input:checked:after {\n  content: \"\";\n  left: -2px;\n  top: 2px;\n  width: 16px;\n  height: 16px;\n  border-radius: 16px;\n  position: relative;\n  background-color: #f01932;\n  display: inline-block;\n  visibility: visible;\n}", "",{"version":3,"sources":["webpack://./src/ui/radioSet/index.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;AACJ;AAAI;EACI,aAAA;EACA,QAAA;EACA,eAAA;AAER;AAAQ;EACI,WAAA;EACA,UAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;EACA,qBAAA;EACA,mBAAA;AAEZ;AACQ;EACI,WAAA;EACA,UAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;EACA,qBAAA;EACA,mBAAA;AACZ","sourcesContent":[".set {\n    display: flex;\n    gap: 16px;\n    label {\n        display: flex;\n        gap: 6px;\n        cursor: pointer;\n\n        input:after {\n            content: '';\n            left: -2px;\n            top: 2px;\n            width: 16px;\n            height: 16px;\n            border-radius: 16px;\n            position: relative;\n            background-color: #d1d3d1;\n            display: inline-block;\n            visibility: visible;\n        }\n\n        input:checked:after {\n            content: '';\n            left: -2px;\n            top: 2px;\n            width: 16px;\n            height: 16px;\n            border-radius: 16px;\n            position: relative;\n            background-color: #f01932;\n            display: inline-block;\n            visibility: visible;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"set": "radioSet_set__y5S1g"
};
export default ___CSS_LOADER_EXPORT___;
