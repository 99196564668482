import { Button } from "ui/button";
import { useActions } from "state/store";

import styles from "./index.module.scss";

export function DeleteVideo({ video, closeModal }: { video: Video; closeModal: () => void }) {
    const { deleteVideo } = useActions();

    function onSubmit() {
        deleteVideo(video.id);
        closeModal();
    }

    return (
        <form className={styles.form}>
            <h2>Удаление видеоролика</h2>
            <p>
                Вы действительно хотите удалить видеоролик: <span>{video.title}</span>
            </p>
            <Button onClick={onSubmit} type="button">
                Удалить
            </Button>
        </form>
    );
}
