import { createSlice } from "@reduxjs/toolkit";

import * as usersActions from "./actions";

const initialState: UsersState = {
    users: [],
    count: 0,
    isSuccess: false,
    isLoading: false,
    isError: false
};

export const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        clearState(state) {
            state.users = [];
            state.count = 0;
            state.isSuccess = false;
            state.isLoading = false;
            state.isError = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(usersActions.getAllUsers.pending, (state: UsersState) => {
                state.isLoading = true;
            })
            .addCase(usersActions.getAllUsers.fulfilled, (state: UsersState, action) => {
                state.users = action.payload[0];
                state.count = action.payload[1];
                state.isSuccess = true;
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(usersActions.getAllUsers.rejected, (state: UsersState) => {
                state.users = [];
                state.count = 0;
                state.isSuccess = false;
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(usersActions.searchUsers.pending, (state: UsersState) => {
                state.isLoading = true;
            })
            .addCase(usersActions.searchUsers.fulfilled, (state: UsersState, action) => {
                state.users = action.payload[0];
                state.count = action.payload[1];
                state.isSuccess = true;
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(usersActions.searchUsers.rejected, (state: UsersState) => {
                state.users = [];
                state.count = 0;
                state.isSuccess = false;
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(usersActions.setPayment.pending, (state: UsersState) => {
                state.isLoading = true;
            })
            .addCase(usersActions.setPayment.fulfilled, (state: UsersState, action) => {
                state.users = state.users.map((user: User) => {
                    if (user.id === action.payload) {
                        user.isPaid = true;
                        return user;
                    } else {
                        return user;
                    }
                });
                state.isSuccess = true;
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(usersActions.setPayment.rejected, (state: UsersState) => {
                state.isSuccess = false;
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(usersActions.deleteUser.pending, (state: UsersState) => {
                state.isLoading = true;
            })
            .addCase(usersActions.deleteUser.fulfilled, (state: UsersState, action) => {
                state.users = state.users.filter((u) => u.id !== action.payload);
                state.isSuccess = true;
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(usersActions.deleteUser.rejected, (state: UsersState) => {
                state.isSuccess = false;
                state.isLoading = false;
                state.isError = true;
            });
    }
});

export const { clearState } = usersSlice.actions;

export const usersSelector = (state: { users: UsersState }) => state.users;
