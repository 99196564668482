import { useState } from "react";
import cn from "classnames";

import arrow_icon from "assets/icon/arrow_icon.svg";
import edit_icon from "assets/icon/edit_icon.svg";
import square2 from "assets/icon/square__2.svg";

import styles from "./faq.module.scss";

export function Faq() {
    const [state, setState] = useState<FAQ[]>([
        {
            title: "Что требуется для использования социальных инструментов?",
            answer: "Перед тем как начать применять инструменты необходимо определиться с тематикой для вашего будущего канала. Тематики идут в комплекте, а вместе с ними подробные инструкции к каждой из них о том как правильно их снимать, со всеми подводными камнями, нюансами и хитростями.",
            isActive: false
        },
        {
            title: "Что конкретно дает использование моих социальных инструментов?",
            answer: "Применение моих социальных инструментов позволяет раскрутить любой канал на ютубе и вывести его на стабильный доход от 600$ в месяц.",
            isActive: false
        },
        {
            title: "Почему именно от 600$ в месяц?",
            answer: "Это средняя сумма доходности интернациональных ютуб каналов каждого из людей применивших мои социальные инструменты на своих каналах. ",
            isActive: false
        },
        {
            title: "Что такое интернациональный контент? Чем он отличается от российского?",
            answer: "Интернациональный контент отличается от российского тем, что в нем нет никакой озвучки и благодаря этому такие видеоролики могут смотреть и понимать зрители любой страны. По причине того что нет привязки к какому-то определенному языку, раскрутить такие видеоролики гораздо легче.",
            isActive: false
        },
        {
            title: "Как мне понять правильно ли я применяю инструменты или нет?",
            answer: "После покупки инструментов вы получаете круглосуточную техническую поддержку от меня и моей команды. Мы работаем с вами вместе. Вместе прорабатываем и корректируем ваш контент, указываем на недочеты и исправляем ошибки, анализируем статистику, настраиваем и оптимизируем ваш канал, делимся хитростями и тонкостями эффективного продвижения.",
            isActive: false
        },
        {
            title: "Когда заканчивается поддержка и сопровождение по инструментам?",
            answer: "К моменту когда вы полностью разберетесь с тем как работают инструменты, подключите монетизацию на свой канал и выйдете на доход от 20$ в день (~10 тыс. просмотров со всех роликов на канале в сутки), вы самостоятельно можете отказаться от поддержки если вы в ней больше не нуждаетесь.",
            isActive: false
        },
        {
            title: "У меня уже есть канал, можно ли применить инструменты на нем?",
            answer: "Можно, но я рекомендую создать новый. Ютуб гораздо лучше продвигает свежесозданные каналы, чем те которые были созданы 10 лет назад. ",
            isActive: false
        },
        {
            title: "Что будет если я изучу и применю социальные инструменты на только что созданном канале?",
            answer: "После применения моих социальных инструментов на своем канале вы гарантированно собственноручно создаете ютуб канал с доходностью от 600$ в месяц.",
            isActive: false
        },
        {
            title: "Сколько тематик идет в комплекте с инструментами?",
            answer: "Многие люди начиная свою деятельность на ютубе не знают с чего начать, что именно нужно снимать что бы получить максимальную результативность за короткий срок. Именно поэтому в комплекте с инструментами идет список из 50 уникальных тематик, а вместе с ними подробные инструкции, записанные лично мною в видеоформате, о том как правильно их снимать, со всеми подводными камнями, нюансами и хитростями.",
            isActive: false
        },
        {
            title: "Обязательно ли нужен компьютер?",
            answer: "Наличие компьютера не является обязательным условием. Работать можно исключительно с телефона. Все приложения, программы для монтажа и редактирования можно установить на любой смартфон. ",
            isActive: false
        },
        {
            title: "Каким образом сформирована цена социальных инструментов?",
            answer: "Основная наша цель — качество, а не количество. У нас не стоит задачи продать инструменты как можно большему количеству людей. Вдобавок к инструментам идет круглосуточная техническая поддержка включающая в себя полную корректировку и настройку контента, пятьдесят уникальных форматов видеороликов с низкой конкуренцией и бесконечная масштабируемость по монетизации для каждого из каналов. Не только от уже проделанного, но и от предстоящего огромного объема работы и сформирована цена инструментов. ",
            isActive: false
        },
        {
            title: "Как происходит подключение к монетизации?",
            answer: "Способов подключения монетизации много. Все актуальные способы подключения можно узнать у менеджера.",
            isActive: false
        }
    ]);

    const handleClick = (id: number) => {
        const newState = state.map((el, i) => {
            if (i === id) {
                el.isActive = !el.isActive;
            }
            return el;
        });
        setState(newState);
    };

    return (
        <section className={cn(styles.section, styles.faq)}>
            <div className={styles.container}>
                <img className={styles.faq__square_1} src={square2} />
                <img className={styles.faq__square_2} src={square2} />
                <h1 className={styles.section__title}>Часто задаваемые вопросы</h1>
                <div className={cn(styles.faq__container, styles.container, styles.grid)}>
                    {state.map((el, i) => (
                        <article
                            key={i}
                            className={cn(el.isActive ? cn(styles.faq__card_active) : styles.faq__card)}
                            onClick={() => handleClick(i)}>
                            <div className={styles.faq__card_header}>
                                <div className={styles.faq__card_content}>
                                    <img className={styles.faq__img} src={edit_icon} />
                                    <h1 className={styles.faq__title}>{el.title}</h1>
                                </div>
                                <img
                                    className={cn(
                                        el.isActive
                                            ? cn(styles.faq__img, styles.faq__btn_avctive)
                                            : cn(styles.faq__img, styles.faq__btn)
                                    )}
                                    src={arrow_icon}
                                />
                            </div>

                            {el.isActive && (
                                <div className={styles.faq__card_dropdown}>
                                    <hr className={styles.faq__card_hr}></hr>
                                    <p className={styles.faq__desc}>{el.answer}</p>
                                </div>
                            )}
                        </article>
                    ))}
                </div>
            </div>
        </section>
    );
}
