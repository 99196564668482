import axios from "axios";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import styles from "./index.module.scss";
import cn from "classnames";
import { useAppSelector } from "state/store";
import { useForm } from "react-hook-form";
import { Input } from "ui/input";
import { Button } from "ui/button";
import { Link } from "react-router-dom";
import bank_icons from "../../../assets/img/pay-system2.webp";
import ssl_img from "../../../assets/img/SSL3.webp";
import { Modal } from "components/modal";
import { SslForm } from "forms/sslForm";

export function PaymentPage() {
    const { user } = useAppSelector((state) => state.core);
    const initialState = {
        TerminalKey: "1683284484670",
        Frame: true,
        Language: "ru",
        Amount: 11700000,
        OrderId: user?.id,
        Description: "Описание",
        Name: "",
        Email: "",
        Phone: "",
        Receipt: {
            Email: "",
            Phone: "",
            EmailCompany: "ka11denblin@gmail.com",
            Taxation: "usn_income",
            Items: [
                {
                    Name: "Социальные инструменты для продвижения в Ютубе",
                    Price: 11700000,
                    Quantity: 1,
                    Amount: 11700000,
                    PaymentMethod: "full_payment",
                    PaymentObject: "service",
                    Tax: "none"
                }
            ]
        }
    };
    const [formValue, setFormValue] = useState(initialState);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const { Name, Phone, Email } = formValue;
    const [ssl, setSsl] = useState<boolean>();
    // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setFormValue({
    //         ...formValue,
    //         [e.target.name]: e.target.value,
    //         Receipt: { ...formValue.Receipt, [e.target.name]: e.target.value }
    //     });
    // };

    const handleClick = async () => {
        setLoading(true);
        try {
            const res = await axios.post("https://securepay.tinkoff.ru/v2/Init", formValue);
            window.location.href = res.data.PaymentURL;
        } catch {
            setError("Не предвиденная ошибка!");
        }
        setLoading(false);
    };

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({ mode: "onChange" });

    useEffect(() => {
        if (formValue.Name && (formValue.Email || formValue.Phone)) {
            handleClick();
        }
    }, [formValue]);

    function onSubmit(data: any) {
        setFormValue((prevFormValue) => ({
            ...prevFormValue,
            Name: data.Name,
            Email: data.Email,
            Phone: data.Phone,
            Receipt: { ...prevFormValue.Receipt, Email: data.Email, Phone: data.Phone }
        }));
    }

    return (
        <div className={styles.container}>
            <div className={styles.payment__content_header}>
                <h1 className={styles.payment__title}>Сумма к оплате: 117.000 Р</h1>
                <p className={styles.payment__subtitle}>TRANSACTION ID: {formValue.OrderId}</p>
            </div>
            <div className={cn(styles.payment__content, styles.payment__inputs)}>
                <h2 className={styles.payment__title}>Заполните данные для получения чека</h2>
                <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                    <Input
                        name="Name"
                        label="Имя пользователя"
                        type="text"
                        errors={errors}
                        register={register("Name", {
                            required: "Поле обязательно для заполнения",
                            minLength: {
                                value: 3,
                                message: "Имя пользователя слишком короткое"
                            },
                            maxLength: {
                                value: 28,
                                message: "Имя пользователя слишком длинное"
                            },
                            pattern: {
                                value: /^[a-zа-яё0-9]+$/i,
                                message: "Только буквы и числа"
                            }
                        })}
                    />
                    <Input
                        name="Email"
                        label="Электронная почта"
                        type="text"
                        errors={errors}
                        register={register("Email", {
                            required: "Поле обязательно для заполнения",
                            pattern: {
                                value: /^[^.](?=[a-z\d!#$%&'*+\-\\/=?.^_`{}|~]+@([a-z-\d]+\.){1,2}[a-z]{2,}$)((?!\.\.).)*$/i,
                                message: "Некорректный Email"
                            }
                        })}
                    />
                    <Input
                        name="Phone"
                        label="Номер телефона"
                        type="phone"
                        errors={errors}
                        register={register("Phone", {
                            required: "Поле обязательно для заполнения",
                            pattern: {
                                value: /^(\+7|8)?[- ]?(\(?\d{3}\)?[- ]?|\d{3}[- ]?)\d{3}[- ]?\d{2}[- ]?\d{2}$/g,
                                message: "Некорретный формат номера"
                            }
                        })}
                    />
                    <Button type="submit">Оплатить</Button>
                    <h1 className={styles.credit_title}>ИЛИ</h1>
                    <h3 className={styles.credit_subtitle}>
                        Если вам требуется рассрочка, напишите менеджеру в телеграм -{" "}
                        <Link target="_blank" to={"https://t.me/manager_lindent"}>
                            <p>@manager_lindent</p>
                        </Link>
                    </h3>
                    <img src={bank_icons} alt="bank_icons" />
                </form>
                <Button
                    onClick={() => setSsl(true)}
                    className={styles.ssl}
                    children={<img src={ssl_img} alt="ssl" style={{ margin: "0px auto", width: "200px" }} />}
                />
                {ssl && <Modal closeModal={() => setSsl(false)} children={<SslForm closeModal={() => setSsl(false)} />} />}
            </div>
        </div>
    );
}
