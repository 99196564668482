import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { ApiService } from "axios.api";

export const getAllVideos = createAsyncThunk<[Video[], number], { id: string; form: PaginationForm }>(
    "videos/getAll",
    async function (data, thunkAPI) {
        try {
            return await ApiService.get(`/videos/${data.id}`, { params: data.form });
        } catch (error) {
            const message = ((error as AxiosError).response?.data as Error).message;
            toast.error(message);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getVideo = createAsyncThunk<Video, string>("videos/get", async function (id, thunkAPI) {
    try {
        return await ApiService.get(`/videos/video/${id}`);
    } catch (error) {
        const message = ((error as AxiosError).response?.data as Error).message;
        toast.error(message);
        return thunkAPI.rejectWithValue(message);
    }
});

export const createVideo = createAsyncThunk<Video, { form: any; id: string }, { rejectValue: string }>(
    "videos/create",
    async function (data, thunkAPI) {
        try {
            const result = await ApiService.post("/videos", data.form);
            await ApiService.post(`/videos/toCategory/${data.id}`, { Vid: result.id });
            toast.success("Видео успешно добавлено");
            return result;
        } catch (error) {
            const message = ((error as AxiosError).response?.data as Error).message;
            toast.error(message);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const updateVideo = createAsyncThunk<
    Category,
    { id: string; form: VideoAPI.UpdateVideoForm },
    { rejectValue: string }
>("videos/update", async function (data, thunkAPI) {
    try {
        const result = await ApiService.patch(`/videos/${data.id}`, data.form);
        toast.success("Видео успешно обновлено");
        return result;
    } catch (error) {
        const message = ((error as AxiosError).response?.data as Error).message;
        toast.error(message);
        return thunkAPI.rejectWithValue(message);
    }
});

export const deleteVideo = createAsyncThunk<string, string>("videos/delete", async function (id, thunkAPI) {
    try {
        await ApiService.delete(`/videos/${id}`);
        toast.success("Видео успешно удалено");
        return id;
    } catch (error) {
        const message = ((error as AxiosError).response?.data as Error).message;
        toast.error(message);
        return thunkAPI.rejectWithValue(message);
    }
});
