// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".restoreAccount_content__TUpsT {\n  width: 100%;\n  min-height: calc(100% - 30px);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 20px;\n}\n.restoreAccount_content__TUpsT .restoreAccount_description__REpci {\n  text-align: center;\n  width: 360px;\n}\n.restoreAccount_content__TUpsT .restoreAccount_back__NHhko {\n  border: none;\n  background-color: rgba(240, 26, 51, 0.3764705882);\n  color: white;\n  width: 300px;\n  transition: 0.2s;\n}\n.restoreAccount_content__TUpsT .restoreAccount_back__NHhko:hover {\n  transition: 0.2s;\n  background-color: #F01A33;\n}", "",{"version":3,"sources":["webpack://./src/pages/auth/restoreAccount/index.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,6BAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AACJ;AAAI;EACI,kBAAA;EACA,YAAA;AAER;AAAI;EACI,YAAA;EACA,iDAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;AAER;AADQ;EACI,gBAAA;EACA,yBAAA;AAGZ","sourcesContent":[".content {\n    width: 100%;\n    min-height: calc(100% - 30px);\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 20px;\n    .description {\n        text-align: center;\n        width: 360px;\n    }\n    .back {\n        border: none;\n        background-color: #F01A3360;\n        color: white;\n        width: 300px;\n        transition: .2s;\n        &:hover {\n            transition: .2s;\n            background-color: #F01A33\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "restoreAccount_content__TUpsT",
	"description": "restoreAccount_description__REpci",
	"back": "restoreAccount_back__NHhko"
};
export default ___CSS_LOADER_EXPORT___;
