import { useState } from "react";
import { useNavigate } from "react-router";

import { Button } from "ui/button";
import { Modal } from "components/modal";
import { EditPassword } from "forms/edit/password";
import { EditUser } from "forms/edit/user";

import { useActions, useAppSelector } from "state/store";

import styles from "./index.module.scss";

import { Link } from "react-router-dom";
import { Output } from "ui/output";

export function ProfilePage() {
    const navigate = useNavigate();
    const { user } = useAppSelector((state) => state.core);
    const { signout } = useActions();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [modalType, setModalType] = useState<ProfileModalForms>("data");

    const editForms: Record<ProfileModalForms, JSX.Element> = {
        data: <EditUser closeModal={() => setIsOpen(false)} />,
        password: <EditPassword closeModal={() => setIsOpen(false)} />
    };

    function openModal(type: ProfileModalForms): void {
        setIsOpen(true);
        setModalType(type);
    }

    function logout() {
        signout();
        navigate("/");
    }

    return (
        <div className={styles.content}>
            <h2>Личный кабинет</h2>
            <div className={styles.user_form}>
                <p>
                    Доступ к инструментам:{" "}
                    <span className={styles[user?.isPaid ? "green" : "red"]}>
                        {user?.isPaid ? "Оплачен" : "Не оплачен"}
                    </span>
                </p>
                <div className={styles.btns}>
                    {user?.isPaid ? (
                        <Link className={styles.linktovideo} to={"/categories"}>
                            Перейти к инструментам
                        </Link>
                    ) : (
                        <Link className={styles.linktopay} to={"/payment"}>
                            Оплатить
                        </Link>
                    )}
                    <Button onClick={() => openModal("password")}>Изменить пароль</Button>
                    {isOpen && <Modal closeModal={() => setIsOpen(false)} children={editForms[modalType]} />}
                    <Button className={styles.logout} onClick={logout}>
                        Выйти из аккаунта
                    </Button>
                </div>
            </div>
        </div>
    );
}
