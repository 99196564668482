// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".signin_form__6\\+vAw {\n  width: 360px;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n@media screen and (max-width: 400px) {\n  .signin_form__6\\+vAw {\n    width: 300px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/forms/signin/index.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AAAI;EALJ;IAMQ,YAAA;EAGN;AACF","sourcesContent":[".form {\n    width: 360px;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    @media screen and (max-width: 400px){\n        width: 300px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "signin_form__6+vAw"
};
export default ___CSS_LOADER_EXPORT___;
