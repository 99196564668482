// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fileInput_input_container__T9ipf {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  height: 80px;\n}\n.fileInput_input_container__T9ipf .fileInput_upload__FPunl {\n  margin-top: 6px;\n  font-size: 16px;\n  color: #F01A33;\n  cursor: pointer;\n  background-color: transparent;\n  border: #F01A33 2px solid;\n  padding: 6px 12px 6px 12px;\n  border-radius: 6px;\n  transition: 0.25s;\n  text-align: center;\n}\n.fileInput_input_container__T9ipf .fileInput_upload__FPunl:hover {\n  color: white;\n  background-color: #F01A33;\n}\n.fileInput_input_container__T9ipf input {\n  display: none;\n}\n.fileInput_input_container__T9ipf .fileInput_error_msg__R-EOa {\n  position: absolute;\n  bottom: 0px;\n  font-size: 14px;\n  color: red;\n}", "",{"version":3,"sources":["webpack://./src/ui/fileInput/index.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;AACJ;AACI;EACI,eAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;EACA,6BAAA;EACA,yBAAA;EACA,0BAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;AACR;AAAQ;EACI,YAAA;EACA,yBAAA;AAEZ;AAEI;EACI,aAAA;AAAR;AAGI;EACI,kBAAA;EACA,WAAA;EACA,eAAA;EACA,UAAA;AADR","sourcesContent":[".input_container {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    height: 80px;\n\n    .upload {\n        margin-top: 6px;\n        font-size: 16px;\n        color: #F01A33;\n        cursor: pointer;\n        background-color: transparent;\n        border: #F01A33 2px solid;\n        padding: 6px 12px 6px 12px;\n        border-radius: 6px;\n        transition: 0.25s;\n        text-align: center;\n        &:hover {\n            color: #ffff;\n            background-color: #F01A33;\n        }\n    }\n\n    input {\n        display: none;\n    }\n\n    .error_msg {\n        position: absolute;\n        bottom: 0px;\n        font-size: 14px;\n        color: red;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input_container": "fileInput_input_container__T9ipf",
	"upload": "fileInput_upload__FPunl",
	"error_msg": "fileInput_error_msg__R-EOa"
};
export default ___CSS_LOADER_EXPORT___;
