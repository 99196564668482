// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@charset \"UTF-8\";\n.checkbox_box__Wl4na {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  position: relative;\n}\n\n.checkbox_popup__D2hgJ {\n  padding: 5px;\n  text-align: center;\n  background-color: #F01A33;\n  color: #ffffff;\n  position: absolute;\n  right: 25px;\n  top: -15px;\n  border-radius: 5px;\n  transition: 0.5s;\n}\n.checkbox_popup__D2hgJ::after {\n  content: \"\";\n  position: absolute;\n  left: 100%; /* Изменено значение */\n  top: 50%;\n  transform: translateY(-50%);\n  border-top: 8px solid transparent;\n  border-bottom: 8px solid transparent;\n  border-left: 8px solid #F01A33; /* Изменено значение */\n}", "",{"version":3,"sources":["webpack://./src/ui/checkbox/index.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,kBAAA;AAEJ;;AACA;EACI,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;EACA,UAAA;EACA,kBAAA;EACA,gBAAA;AAEJ;AADI;EACI,WAAA;EACA,kBAAA;EACA,UAAA,EAAA,sBAAA;EACA,QAAA;EACA,2BAAA;EACA,iCAAA;EACA,oCAAA;EACA,8BAAA,EAAA,sBAAA;AAGR","sourcesContent":[".box {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    position: relative;\n}\n\n.popup {\n    padding: 5px;\n    text-align: center;\n    background-color: #F01A33;\n    color: #ffffff;\n    position: absolute;\n    right: 25px;\n    top: -15px;\n    border-radius: 5px;\n    transition: .5s;\n    &::after {\n        content: \"\";\n        position: absolute;\n        left: 100%; /* Изменено значение */\n        top: 50%;\n        transform: translateY(-50%);\n        border-top: 8px solid transparent;\n        border-bottom: 8px solid transparent;\n        border-left: 8px solid #F01A33; /* Изменено значение */\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "checkbox_box__Wl4na",
	"popup": "checkbox_popup__D2hgJ"
};
export default ___CSS_LOADER_EXPORT___;
