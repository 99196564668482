// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_modal__f4cjD {\n  position: fixed;\n  left: 0px;\n  top: 0px;\n  z-index: 99;\n  width: 100vw;\n  height: 100vh;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n.modal_modal__f4cjD .modal_modal_content__2ljWW {\n  cursor: default;\n  min-width: 360px;\n  min-height: 160px;\n  border-radius: 6px;\n  background-color: white;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 30px 30px 44px 30px;\n}", "",{"version":3,"sources":["webpack://./src/components/modal/index.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,SAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,oCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AACJ;AAAI;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,uBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,4BAAA;AAER","sourcesContent":[".modal {\n    position: fixed;\n    left: 0px;\n    top: 0px;\n    z-index: 99;\n    width: 100vw;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n    .modal_content {\n        cursor: default;\n        min-width: 360px;\n        min-height: 160px;\n        border-radius: 6px;\n        background-color: white;\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        padding: 30px 30px 44px 30px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal_modal__f4cjD",
	"modal_content": "modal_modal_content__2ljWW"
};
export default ___CSS_LOADER_EXPORT___;
