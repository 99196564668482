import styles from "./index.module.scss";

export function Checkbox({ name, register, errors }: UI.Checkbox): JSX.Element {
    return (
        <div className={styles.box}>
            <input type="checkbox" {...register} />
            {errors[name] && <div className={styles.popup}>{errors[name].message as string}</div>}
        </div>
    );
}
